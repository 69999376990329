import classNames from "classnames";
import CiroContainer from "../../components/shared/CiroContainer";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroTitle from "../../components/shared/CiroTitle";
import MobileNumbersOverview, {
  MobileNumbersOverview_Query,
} from "../../components/mobileNumbers/MobileNumbersOverview";
import { gql, useQuery } from "@apollo/client";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import MobileNumbersTable, {
  MobileNumbersTable_Query,
} from "../../components/mobileNumbers/MobileNumbersTable";
import MobileNumbersGetStartedContainer from "../../components/mobileNumbers/MobileNumbersGetStartedContainer";
import CiroButton from "../../components/shared/CiroButton";
import { useState } from "react";
import MobileNumbersIntegrationModal from "../../components/mobileNumbers/mobileNumbersIntegrationModal";
import MobileNumbersRunButton from "../../components/mobileNumbers/MobileNumbersRunButton";
import MobileNumbersRefreshButton from "../../components/mobileNumbers/MobileNumbersRefreshButton";
import DotIcon from "../../assets/img/icons/DotIcon";
import SalesforceIcon from "../../assets/img/icons/SalesforceIcon";
import ApolloIcon from "../../assets/img/icons/ApolloIcon";
import { useNavigate } from "react-router-dom";
import {
  MobileNumbers_QueryQuery,
  MobileNumbers_QueryQueryVariables,
} from "../../__generated__/graphql";
import HubspotIcon from "../../assets/img/icons/HubspotIcon";

export const MobileNumbers_Query = gql`
  query MobileNumbers_Query {
    ...MobileNumbersOverview_Query
    ...MobileNumbersTable_Query
    userAccount {
      org {
        apolloIntegration {
          id
        }
        organizationMergeIntegration {
          integration
          invalid_number_statuses
        }
        ciroNumbersIntegration
      }
    }
    phoneNumbersRequestTransactions {
      id
      created_at
      requestedContactsCount
      newNumbersCount
      numbersCalledCount
      conversationsCreatedCount
      status
      creditsUsed
    }
    phoneNumbersRequestTransactionsCount
    phoneNumbersRecoveredCount
    conversationsCreatedCount
  }
  ${MobileNumbersOverview_Query}
  ${MobileNumbersTable_Query}
`;

const MobileNumbersLoading = () => {
  return (
    <div>
      <SkeletonLoading
        containerCustomerClassName={classNames("ciro-v1-px-60", "ciro-v1-pt-8")}
        numSkeletons={3}
        skeletonHeight={"8rem"}
      />
      <SkeletonLoading
        containerCustomerClassName={classNames(
          "ciro-v1-px-60",
          "ciro-v1-py-16",
        )}
        numSkeletons={25}
        skeletonHeight={"3rem"}
      />
    </div>
  );
};

const MobileNumbers = () => {
  const { data, loading } = useQuery<
    MobileNumbers_QueryQuery,
    MobileNumbers_QueryQueryVariables
  >(MobileNumbers_Query);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const navigator = useNavigate();

  const hasApolloIntegration =
    data?.userAccount?.org?.apolloIntegration != null;
  const hasCrmIntegration =
    data?.userAccount?.org?.organizationMergeIntegration != null;
  const hasIntegration = hasApolloIntegration || hasCrmIntegration;

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-flex-row",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
          "ciro-v1-items-center",
        )}
      >
        <CiroBreadCrumbs
          values={["Enrich Data", "Mobile Numbers"]}
          href="/mobile-numbers"
        />
      </div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-w-full",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
          )}
        >
          <CiroTitle title="Mobile Numbers" />
          <span className={classNames("ciro-v1-px-2")}>
            <MobileNumbersRefreshButton />
          </span>
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-row",
            "ciro-v1-items-center",
            "ciro-v1-gap-2",
          )}
        >
          {!hasIntegration && (
            <CiroButton
              analyticsField="Open Integration Settings"
              onClick={() => setIntegrationModalOpen(true)}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                Connect your CRM
              </div>
            </CiroButton>
          )}
          {hasApolloIntegration && (
            <CiroButton
              analyticsField="Open Apollo settings"
              onClick={() => navigator("/mobile-numbers/apollo-integration")}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                <span className={classNames("ciro-v1-mr-2")}>
                  <ApolloIcon />
                </span>
                Manage Apollo Connection
                <span
                  className={classNames(
                    "ciro-v1-text-green-500",
                    "ciro-v1-ml-2",
                  )}
                >
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasCrmIntegration && (
            <CiroButton
              analyticsField="Open crm mobile connection settings"
              onClick={() => {
                navigator("/mobile-numbers/crm-integration");
              }}
            >
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-flex-row",
                  "ciro-v1-items-center",
                )}
              >
                <span className={classNames("ciro-v1-mr-2")}>
                  {data?.userAccount?.org?.organizationMergeIntegration
                    ?.integration === "Salesforce" ? (
                    <SalesforceIcon />
                  ) : (
                    <HubspotIcon />
                  )}
                </span>
                Manage{" "}
                {
                  data?.userAccount?.org?.organizationMergeIntegration
                    ?.integration
                }{" "}
                Connection
                <span
                  className={classNames(
                    "ciro-v1-text-green-500",
                    "ciro-v1-ml-2",
                  )}
                >
                  <DotIcon />
                </span>
              </div>
            </CiroButton>
          )}
          {hasIntegration && <MobileNumbersRunButton />}
        </div>
      </div>
      {loading && <MobileNumbersLoading />}
      {data && <MobileNumbersOverview data={data} />}
      {hasIntegration && (
        <div className={classNames("ciro-v1-w-full", "ciro-v1-mt-8")}>
          <MobileNumbersTable data={data} />
        </div>
      )}
      {!hasIntegration && <MobileNumbersGetStartedContainer />}
      <MobileNumbersIntegrationModal
        isOpen={integrationModalOpen}
        onClose={() => setIntegrationModalOpen(false)}
      />
    </CiroContainer>
  );
};

export default MobileNumbers;
