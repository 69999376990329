import classNames from "classnames";
import MobileNumbersOverviewCard from "./MobileNumbersOverviewCard";
import { gql } from "@apollo/client";
import { MobileNumbersOverview_QueryFragment } from "../../__generated__/graphql";

export const MobileNumbersOverview_Query = gql`
  fragment MobileNumbersOverview_Query on Query {
    phoneNumbersRequestTransactionsCount
    phoneNumbersRecoveredCount
    conversationsCreatedCount
  }
`;

const MobileNumbersOverview = ({
  data,
}: {
  data: MobileNumbersOverview_QueryFragment;
}) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-row",
        "ciro-v1-justify-between",
        "ciro-v1-gap-4",
        "ciro-v1-mt-4",
        "ciro-v1-w-full",
      )}
    >
      <MobileNumbersOverviewCard
        amount={data.phoneNumbersRequestTransactionsCount}
        description="Pipeline runs"
      />
      <MobileNumbersOverviewCard
        amount={data.phoneNumbersRecoveredCount}
        description="Total numbers recovered"
      />
      <MobileNumbersOverviewCard
        amount={data.conversationsCreatedCount}
        description="Conversations created"
      />
    </div>
  );
};

export default MobileNumbersOverview;
