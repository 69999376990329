import classNames from "classnames";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { TrashIcon, CheckIcon } from "@heroicons/react/20/solid";
import {
  AccountFilterEnum,
  PermissionsEnum,
  VisibleAccountFieldsEnum,
} from "../../../__generated__/graphql";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroTextInput from "../../shared/CiroTextInput";
import { useState } from "react";

const ACCOUNT_FIELD_PERMISSIONS = [PermissionsEnum.AccountFilters];

const ACCOUNT_FILTER_PERMISSIONS = [PermissionsEnum.VisibleAccountFields];
const BOOLEAN_PERMISSIONS = [
  PermissionsEnum.CanDestroyCollections,
  PermissionsEnum.CanEditCollectionsByPk,
  PermissionsEnum.CanEditCollectionsByQuery,
  PermissionsEnum.CanExportAccountsData,
  PermissionsEnum.CanViewCiroNumbers,
  PermissionsEnum.CanViewEnrichments,
  PermissionsEnum.CanSearchWebPageContent,
  PermissionsEnum.CanViewCollections,
];

const INTEGER_PERMISSIONS = [PermissionsEnum.AccountPaginationLimit];

const ALL_ACCOUNTED_FOR_PERMISSIONS = [
  ...ACCOUNT_FIELD_PERMISSIONS,
  ...ACCOUNT_FILTER_PERMISSIONS,
  ...BOOLEAN_PERMISSIONS,
  ...INTEGER_PERMISSIONS,
];

interface IAdminCustomPermissionFieldProps {
  handleDelete: () => void;
  handleUpsert: (v: {
    variables: {
      orgCiroId: number;
      permissionType: PermissionsEnum;
      value: string;
    };
  }) => void;
  isLoading: boolean;
  orgCiroId: number;
  permission: PermissionsEnum;
  value: any;
}

const AdminCustomPermissionField = ({
  handleDelete,
  handleUpsert,
  isLoading,
  orgCiroId,
  permission,
  value,
}: IAdminCustomPermissionFieldProps) => {
  const [accountFilterPermission, setAccountFilterPermission] = useState<
    AccountFilterEnum[]
  >(() => {
    return value && ACCOUNT_FILTER_PERMISSIONS.includes(permission)
      ? JSON.parse(value)
      : [];
  });
  const [accountFieldPermission, setAccountFieldPermission] = useState<
    VisibleAccountFieldsEnum[]
  >(() => {
    return value && ACCOUNT_FIELD_PERMISSIONS.includes(permission)
      ? JSON.parse(value)
      : [];
  });
  const [booleanPermission, setBooleanPermission] = useState<boolean | null>(
    () => {
      return value && BOOLEAN_PERMISSIONS.includes(permission)
        ? JSON.parse(value)
        : null;
    },
  );
  const [integerPermission, setIntegerPermission] = useState<number | null>(
    () => {
      return value && INTEGER_PERMISSIONS.includes(permission)
        ? JSON.parse(value)
        : null;
    },
  );

  return (
    <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
      <div className={classNames("ciro-v1-w-full")}>
        {ACCOUNT_FILTER_PERMISSIONS.includes(permission) && (
          <CiroDropDown
            isMulti
            placeholder="Select filters to use on account"
            options={Object.values(AccountFilterEnum).map((filter) => {
              return {
                label: filter,
                value: filter,
              };
            })}
            value={accountFilterPermission}
            onChange={(v) => setAccountFilterPermission(v)}
          />
        )}
        {ACCOUNT_FIELD_PERMISSIONS.includes(permission) && (
          <CiroDropDown
            isMulti
            placeholder="Select fields to view on account"
            options={Object.values(VisibleAccountFieldsEnum).map((filter) => {
              return {
                label: filter,
                value: filter,
              };
            })}
            value={accountFieldPermission}
            onChange={(v) => setAccountFieldPermission(v)}
          />
        )}
        {BOOLEAN_PERMISSIONS.includes(permission) && (
          <CiroDropDown
            isMulti={false}
            isClearable={false}
            options={[
              {
                value: true,
                label: "True",
              },
              {
                value: false,
                label: "False",
              },
            ]}
            value={booleanPermission}
            onChange={(v) => setBooleanPermission(v)}
          />
        )}
        {INTEGER_PERMISSIONS.includes(permission) && (
          <CiroTextInput
            type="number"
            value={String(integerPermission)}
            onChange={(v) => {
              setIntegerPermission(Number(v.target.value) || 0);
            }}
          />
        )}
        {!ALL_ACCOUNTED_FOR_PERMISSIONS.includes(permission) && (
          <div>Contact Engineering To Update</div>
        )}
      </div>
      <div className={classNames("ciro-v1-ml-4", "ciro-v1-flex")}>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"Update custom permission"}
          disabled={isLoading}
          onClick={() => {
            const value =
              (ACCOUNT_FILTER_PERMISSIONS.includes(permission) &&
                accountFilterPermission) ||
              (ACCOUNT_FIELD_PERMISSIONS.includes(permission) &&
                accountFieldPermission) ||
              (BOOLEAN_PERMISSIONS.includes(permission) && booleanPermission) ||
              (INTEGER_PERMISSIONS.includes(permission) && integerPermission);
            handleUpsert({
              variables: {
                orgCiroId,
                permissionType: permission,
                value: JSON.stringify(value),
              },
            });
          }}
        >
          <CheckIcon
            className={classNames("ciro-v1-h-5", {
              "ciro-v1-text-gray-500": isLoading,
            })}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
      <div className={classNames("ciro-v1-ml-4", "ciro-v1-flex")}>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"Deleted custom permission"}
          disabled={isLoading}
          onClick={handleDelete}
        >
          <TrashIcon
            className={classNames("ciro-v1-h-5", {
              "ciro-v1-text-gray-500": isLoading,
            })}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
    </div>
  );
};

export default AdminCustomPermissionField;
