import { ArrowPathIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  JobStateEnum,
  MobileNumbersRefreshButton_CiroNumbersIntegrationRefreshJobStatusQuery,
  MobileNumbersRefreshButton_CiroNumbersIntegrationRefreshJobStatusQueryVariables,
  MobileNumbersRefreshButton_RefreshCiroNumbersIntegrationMutation,
  MobileNumbersRefreshButton_RefreshCiroNumbersIntegrationMutationVariables,
  MobileNumbersRefreshButton_UserAccountQuery,
  MobileNumbersRefreshButton_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import CiroSpinner from "../shared/CiroSpinner";
import classNames from "classnames";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const MobileNumbersRefreshButton_RefreshCiroNumbersIntegration = gql`
  mutation MobileNumbersRefreshButton_RefreshCiroNumbersIntegration {
    refreshCiroNumbersIntegration {
      success
      jobId
    }
  }
`;

const MobileNumbersRefreshButton_CiroNumbersRefreshJobStatus = gql`
  query MobileNumbersRefreshButton_CiroNumbersIntegrationRefreshJobStatus {
    ciroNumbersIntegrationRefreshJobStatus {
      state
    }
  }
`;

const MobileNumbersRefreshButton_UserAccount = gql`
  query MobileNumbersRefreshButton_UserAccount {
    userAccount {
      org {
        ciroNumbersIntegration
      }
    }
  }
`;

const MobileNumbersRefreshButton = () => {
  const {
    data: jobStatusData,
    loading: jobStatusLoading,
    refetch,
  } = useQuery<
    MobileNumbersRefreshButton_CiroNumbersIntegrationRefreshJobStatusQuery,
    MobileNumbersRefreshButton_CiroNumbersIntegrationRefreshJobStatusQueryVariables
  >(MobileNumbersRefreshButton_CiroNumbersRefreshJobStatus);

  const { data: userAccountData } = useQuery<
    MobileNumbersRefreshButton_UserAccountQuery,
    MobileNumbersRefreshButton_UserAccountQueryVariables
  >(MobileNumbersRefreshButton_UserAccount);

  const [refreshCiroNumbersIntegration, { loading: refreshLoading }] =
    useMutation<
      MobileNumbersRefreshButton_RefreshCiroNumbersIntegrationMutation,
      MobileNumbersRefreshButton_RefreshCiroNumbersIntegrationMutationVariables
    >(MobileNumbersRefreshButton_RefreshCiroNumbersIntegration);

  const isJobActive =
    jobStatusData?.ciroNumbersIntegrationRefreshJobStatus?.state != null &&
    [JobStateEnum.Active, JobStateEnum.Delayed, JobStateEnum.Waiting].includes(
      jobStatusData?.ciroNumbersIntegrationRefreshJobStatus?.state,
    );

  const canStartNewRefresh =
    !isJobActive && !refreshLoading && !jobStatusLoading;

  const integration = userAccountData?.userAccount?.org?.ciroNumbersIntegration;

  const [prevJobState, setPrevJobState] = useState<JobStateEnum | null>(null);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (isJobActive) {
      intervalId = setInterval(() => {
        refetch();
      }, 10000);
    } else if (
      prevJobState === JobStateEnum.Active &&
      !isJobActive &&
      jobStatusData?.ciroNumbersIntegrationRefreshJobStatus?.state ===
        JobStateEnum.Completed
    ) {
      toast.success("Apollo data refresh completed successfully!");
    } else if (
      prevJobState === JobStateEnum.Active &&
      !isJobActive &&
      jobStatusData?.ciroNumbersIntegrationRefreshJobStatus?.state ===
        JobStateEnum.Failed
    ) {
      toast.error("Apollo data refresh failed!");
    }

    setPrevJobState(
      jobStatusData?.ciroNumbersIntegrationRefreshJobStatus?.state || null,
    );

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isJobActive, refetch, jobStatusData, prevJobState]);

  // Leaving this setup for future additional tooltips
  let tooltip = null;
  if (isJobActive) {
    tooltip = "Job is active";
  } else if (canStartNewRefresh && integration) {
    tooltip = `Refresh connection to ${integration}`;
  }

  return (
    <CiroTooltipContainer tooltip={tooltip} disabled={!tooltip}>
      {isJobActive && <CiroSpinner loading />}
      {!isJobActive && (
        <CiroButton
          customClassName={classNames("disabled:ciro-v1-text-zinc-500")}
          analyticsField="Refresh apollo connection"
          onClick={() => {
            refreshCiroNumbersIntegration({
              refetchQueries: [
                MobileNumbersRefreshButton_CiroNumbersRefreshJobStatus,
              ],
            });
          }}
          disabled={!canStartNewRefresh}
          style={CiroButtonStyleEnum.UNSTYLED}
        >
          <ArrowPathIcon className="ciro-v1-h-6 ciro-v1-w-6" />
        </CiroButton>
      )}
    </CiroTooltipContainer>
  );
};

export default MobileNumbersRefreshButton;
