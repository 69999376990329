interface IBoltWindIcon {
  width?: number | string;
  height?: number | string;
}

const BoltWindIcon = ({ width = 24, height = 24 }: IBoltWindIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3658 5.63335V10.3053H20.3525C20.5893 10.2999 20.8093 10.4268 20.9232 10.6345C21.037 10.8422 21.0256 11.096 20.8937 11.2927L15.8256 18.7188C15.6658 18.9524 15.3736 19.0564 15.1022 18.9761C14.8307 18.8958 14.6421 18.6496 14.6351 18.3667V13.6917H10.6505C10.4137 13.6971 10.1936 13.5702 10.0798 13.3625C9.96595 13.1548 9.9773 12.901 10.1092 12.7043L15.1753 5.2812C15.3351 5.04755 15.6273 4.94362 15.8988 5.02391C16.1702 5.1042 16.3589 5.35038 16.3658 5.63335V5.63335Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99877 18.0025H3.74658"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.37267 12.5002H1.99585"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99877 6.99789H3.74658"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BoltWindIcon;
