import React from "react";
import classNames from "classnames";

interface ICiroTableRowProps {
  children: any;
  clickable?: boolean;
  sticky?: boolean;
}

const CiroTableRow = ({
  children,
  clickable = true,
  sticky = false,
}: ICiroTableRowProps) => {
  return (
    <tr
      className={classNames("ciro-v1-table-row", "ciro-v1-bg-white", {
        "ciro-v1-cursor-pointer": clickable,
        "hover:ciro-v1-bg-neutral-200": clickable,
        "ciro-v1-sticky": sticky,
        "ciro-v1-z-10": sticky,
        "ciro-v1-top-0": sticky,
        "ciro-v1-opacity-100": sticky,
      })}
    >
      {children}
    </tr>
  );
};

export default CiroTableRow;
