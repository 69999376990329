import React, { useCallback, useEffect, useRef } from "react";
import { gql } from "@apollo/client";
import { LinkCrmButton_UserAccountFragment } from "../../__generated__/graphql";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { dateToTimeAgo } from "../../utils/formatters";
import classNames from "classnames";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

export const LinkCRMButton_UserAccount = gql`
  fragment LinkCRMButton_UserAccount on UserAccount {
    id
    mergeLinkToken
    hasCRMConnection
    hasCrmIntegrationEnabled
    org {
      organizationMergeIntegration {
        integration
        last_matched_at
      }
    }
  }
`;

interface ILinkCRMButtonProps {
  userAccount: LinkCrmButton_UserAccountFragment | null | undefined;
  onTokenStored?: () => void; // Callback to be executed after token is stored
}

export function LinkCRMButton({
  userAccount,
  onTokenStored,
}: ILinkCRMButtonProps) {
  // Need to save the initial prop value in a ref because the prop value will change,
  // and changing the value will reload the mergeLink component, creating an error
  const initialPropValue = useRef(userAccount?.mergeLinkToken);
  const lastSyncedAt =
    userAccount?.org?.organizationMergeIntegration?.last_matched_at;
  const crmIntegration =
    userAccount?.org?.organizationMergeIntegration?.integration;

  useEffect(() => {
    if (initialPropValue.current == null) {
      initialPropValue.current = userAccount?.mergeLinkToken;
    }
  }, [userAccount?.mergeLinkToken]);

  const onSuccess = useCallback(
    async (publicToken: string) => {
      onTokenStored?.();
    },
    [onTokenStored],
  );

  const { open, isReady } = useMergeLink({
    linkToken: String(initialPropValue.current),
    onSuccess,
  });

  if (!userAccount?.hasCrmIntegrationEnabled) {
    return null;
  }

  if (userAccount?.hasCRMConnection) {
    return (
      <div className={classNames("ciro-v1-pt-2")}>
        {lastSyncedAt ? (
          <div
            className={classNames(
              "ciro-v1-text-sm",
              "ciro-v1-text-neutral-600",
              "ciro-v1-flex",
              "ciro-v1-items-center",
            )}
          >
            <ArrowPathIcon
              height={20}
              className={classNames("ciro-v1-pr-2", "ciro-v1-text-blue-500")}
            />
            {crmIntegration ? crmIntegration : "CRM"} synced{" "}
            {dateToTimeAgo(lastSyncedAt)}
          </div>
        ) : (
          <div className="ciro-v1-text-slate-400">CRM sync pending</div>
        )}
      </div>
    );
  }

  return (
    <CiroButton
      style={CiroButtonStyleEnum.LOUD}
      onClick={open}
      disabled={!isReady}
      analyticsField="Connect CRM"
    >
      Connect CRM
    </CiroButton>
  );
}
