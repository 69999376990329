import { useState } from "react";
import classNames from "classnames";
import NavLink from "./NavLink";
import {
  ArchiveBoxArrowDownIcon,
  BriefcaseIcon,
  ChevronDownIcon,
  LockClosedIcon,
  Square3Stack3DIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import PuzzleIcon from "../../assets/img/icons/PuzzleIcon";
import DatabaseIcon from "../../assets/img/icons/DatabaseIcon";
import { NavigationContainer_PermissionsQuery } from "../../__generated__/graphql";
import BoltWindIcon from "../../assets/img/icons/BoltWindIcon";

interface INavigationLinksSection {
  fullWidth?: boolean;
  navData?: NavigationContainer_PermissionsQuery;
  stopImpersonating: () => void;
}

const NavigationLinksSection = ({
  fullWidth,
  navData,
  stopImpersonating,
}: INavigationLinksSection) => {
  const [enrichDataExpanded, setEnrichDataExpanded] = useState(false);
  const canViewEnrichments = navData?.permissions?.canViewEnrichments;
  const canViewCiroNumbers = navData?.permissions?.canViewCiroNumbers;
  const canViewUploadCsv = navData?.permissions?.canViewUploadCsv;
  const hasAdminPermissions = navData?.permissions?.hasAdminPermissions;
  const impersonating = navData?.userAccount?.impersonating;

  return (
    <>
      <NavLink
        href="/"
        title="Accounts"
        fullWidth={fullWidth}
        icon={<BriefcaseIcon className={classNames("ciro-v1-h-5")} />}
      />
      <NavLink
        className="test-collections-link"
        href="/collections"
        additionalMatches={["/collections/:id"]}
        title="Collections"
        fullWidth={fullWidth}
        icon={<Square3Stack3DIcon className={classNames("ciro-v1-h-5")} />}
      />
      {canViewEnrichments && !(canViewCiroNumbers || impersonating) && (
        <NavLink
          href="/enrichments"
          title="Enrich Data"
          fullWidth={fullWidth}
          icon={<BoltWindIcon height="20" width="20" />}
        />
      )}
      {canViewEnrichments && (canViewCiroNumbers || impersonating) && (
        <>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-items-center",
              "ciro-v1-justify-around",
              "ciro-v1-cursor-pointer",
              { "ciro-v1-px-4": !fullWidth },
              "ciro-v1-py-2",
              "ciro-v1-text-neutral-500",
              "ciro-v1-ml-3"
            )}
            onClick={() => setEnrichDataExpanded(!enrichDataExpanded)}
          >
            <div className={classNames("ciro-v1-flex", "ciro-v1-flex-row", "ciro-v1-items-center")}>
              <PuzzleIcon height="24" width="24" />
              <span className={classNames("ciro-v1-ml-3")}>Enrich Data</span>
            </div>
            <ChevronDownIcon
              className={classNames(
                "ciro-v1-h-5",
                "ciro-v1-w-5",
                "ciro-v1-ml-auto",
                {
                  "ciro-v1-transform ciro-v1-rotate-180": enrichDataExpanded,
                }
              )}
            />
          </div>
          {enrichDataExpanded && (
            <div className="ciro-v1-ml-6">
              <NavLink
                href="/enrichments"
                title="Enrichments"
                fullWidth={fullWidth}
                icon={<BoltWindIcon height="20" width="20" />}
              />
              <NavLink
                href="/mobile-numbers"
                title="Ciro Numbers"
                fullWidth={fullWidth}
                icon={<DatabaseIcon height="20" width="20" />}
              />
            </div>
          )}
        </>
      )}
      {canViewUploadCsv && (
        <NavLink
          href="/upload-csv"
          title="Upload CSV"
          fullWidth={fullWidth}
          icon={
            <ArchiveBoxArrowDownIcon className={classNames("ciro-v1-h-5")} />
          }
        />
      )}
      <NavLink
        href="/profile"
        title="Profile"
        fullWidth={fullWidth}
        icon={<UserCircleIcon className={classNames("ciro-v1-h-5")} />}
      />
      {hasAdminPermissions && (
        <NavLink
          href="/admin"
          title="Admin"
          fullWidth={fullWidth}
          icon={<LockClosedIcon className={classNames("ciro-v1-h-5")} />}
        />
      )}
    </>
  );
};

export default NavigationLinksSection;
