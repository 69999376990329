import React from "react";
import { ageOptions } from "../../utils/table-settings";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";

interface IOwnerAgeFilterProps {
  ageFilter: string[];
  blurred?: boolean;
  notAgeFilter: string[];
  resetOffset: () => void;
  setAgeFilter: (v: string[]) => void;
  setNotAgeFilter: (v: string[]) => void;
}

function OwnerAgeFilter({
  ageFilter,
  blurred = false,
  notAgeFilter,
  resetOffset,
  setAgeFilter,
  setNotAgeFilter,
}: IOwnerAgeFilterProps) {
  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={
        (ageFilter.length ? 1 : 0) + (notAgeFilter.length ? 1 : 0)
      }
      sectionTitle="Owner Age"
      filterOptions={[
        {
          label: "Include any of:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={ageOptions}
              value={ageFilter}
              onChange={(newValue) => {
                setAgeFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
        {
          label: "Exclude:",
          filter: (
            <CiroDropDown
              isMulti
              blurred={blurred}
              options={ageOptions}
              value={notAgeFilter}
              onChange={(newValue) => {
                setNotAgeFilter(newValue);
                resetOffset();
              }}
            />
          ),
        },
      ]}
      smbFilter
    />
  );
}

export default OwnerAgeFilter;
