import { useAuth0 } from "@auth0/auth0-react";
import logo from "../../assets/ciro_logo_transparent.png";
import NavLink from "./NavLink";
import LogoutIcon from "../../assets/img/icons/LogoutIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { ISharedNavigationProps } from "./NavigationContainer";
import NavigationLinksSection from "./NavigationLinksSection";
import CiroCoinPill from "../shared/CiroCoinPill";

const LargeScreenNavigation = ({
  loading,
  navData,
  stopImpersonating,
}: ISharedNavigationProps) => {
  const { isAuthenticated, logout } = useAuth0();

  if (!isAuthenticated || loading) {
    return null;
  }

  const impersonating = navData?.userAccount?.impersonating;
  const creditPlan = navData?.userAccount?.org?.credit_plan;

  return (
    <div
      className={classNames([
        "ciro-v1-bg-white",
        "ciro-v1-border-gray-200",
        "ciro-v1-border-r-2",
        "ciro-v1-fixed",
        "ciro-v1-flex-col",
        "ciro-v1-flex",
        "ciro-v1-h-screen",
        "ciro-v1-hidden",
        "ciro-v1-justify-between",
        "lg:ciro-v1-w-72",
        "lg:ciro-v1-flex",
      ])}
    >
      <div className="ciro-v1-flex ciro-v1-flex-col">
        <Link to="/">
          <img
            src={logo}
            width="80"
            height="70"
            className="ciro-v1-ml-5 ciro-v1-inline-block align-top ciro-v1-my-6"
            alt="Ciro logo"
          />
        </Link>
        <NavigationLinksSection
          navData={navData}
          stopImpersonating={stopImpersonating}
        />
      </div>
      <div>
        {creditPlan && (
          <div className={classNames(
            "ciro-v1-bg-gray-100",
            "ciro-v1-mx-4",
            "ciro-v1-mb-4",
            "ciro-v1-p-4",
            "ciro-v1-rounded-lg"
          )}>
            <div className={classNames(
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-justify-between"
            )}>
              <span className={classNames(
                "ciro-v1-text-neutral-900",
                "ciro-v1-font-sans",
                "ciro-v1-text-sm",
                "ciro-v1-font-semibold",
                "ciro-v1-leading-5",
                "ciro-v1-tracking-normal",
                "ciro-v1-mr-2"
              )}>
                Credits
              </span>
              <CiroCoinPill amount={creditPlan.balance || 0} />
            </div>
          </div>
        )}
        {impersonating && (
          <NavLink
            onClick={stopImpersonating}
            title={`Impersonating (${impersonating.email})`}
            icon={<LogoutIcon />}
          />
        )}
        {!impersonating && (
          <NavLink
            onClick={() => {
              logout({ returnTo: window.location.origin });
              sessionStorage.localStorage.clear();
            }}
            title="Log out"
            icon={<LogoutIcon />}
          />
        )}
        <div className="ciro-v1-flex ciro-v1-justify-evenly ciro-v1-py-4 ciro-v1-border-t-2 ciro-v1-border-t-stone-200">
          <a
            className="ciro-v1-no-underline ciro-v1-my-1 ciro-v1-text-stone-300 ciro-v1-text-xs hover:ciro-v1-text-neutral-600"
            href="https://www.ciro.io/terms-of-service"
          >
            Terms of service
          </a>
          <a
            className="ciro-v1-no-underline ciro-v1-my-1 ciro-v1-text-stone-300 ciro-v1-text-xs hover:ciro-v1-text-neutral-600"
            href="https://www.ciro.io/privacy-policy"
          >
            Privacy policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default LargeScreenNavigation;
