import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, PRODUCTION_ORIGIN, POSTHOG_HOST, POSTHOG_KEY } from "./clientSideVariables";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://cb12b4b6b9704f2db39fd23440c0c098@o4504017469505536.ingest.sentry.io/4504017471143937",
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [PRODUCTION_ORIGIN, "localhost:3000"],
        networkCaptureBodies: true
      }),
    ],

    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById("root");
const root = createRoot(container);
const postHogConfig = {
  api_host: POSTHOG_HOST,
}

if (process.env.NODE_ENV !== "production") {
  postHogConfig.disable_session_recording = true;
  console.log("Disabling session recording");
}

  root.render(
    <BrowserRouter>
      <PostHogProvider
        apiKey={POSTHOG_KEY}
        options={postHogConfig}
      >
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
        >
          <App></App>
        </Auth0Provider>
      </PostHogProvider>
    </BrowserRouter>,
  );
