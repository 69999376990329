import CiroModal from "../../shared/CiroModal";
import MobileNumbersGetStarted, {
  MobileNumbersGetStarted_UserAccount,
} from "./MobileNumbersGetStarted";
import { gql, useQuery } from "@apollo/client";
import Loading from "../../shared/Loading";
import {
  MobileNumbersIntegrationModal_UserAccountQuery,
  MobileNumbersIntegrationModal_UserAccountQueryVariables,
} from "../../../__generated__/graphql";

interface IMobileNumbersIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MobileNumbersIntegrationModal_UserAccount = gql`
  query MobileNumbersIntegrationModal_UserAccount {
    userAccount {
      id
      org {
        organizationMergeIntegration {
          integration
          last_matched_at
        }
      }
      ...MobileNumbersGetStarted_UserAccount
    }
  }
  ${MobileNumbersGetStarted_UserAccount}
`;

const MobileNumbersIntegrationModal = ({
  isOpen,
  onClose,
}: IMobileNumbersIntegrationModalProps) => {
  const { data, loading, error } = useQuery<
    MobileNumbersIntegrationModal_UserAccountQuery,
    MobileNumbersIntegrationModal_UserAccountQueryVariables
  >(MobileNumbersIntegrationModal_UserAccount);

  return (
    <CiroModal isOpen={isOpen} onClose={onClose}>
      {error && <div>{error.message}</div>}

      {loading && <Loading />}
      {data && data.userAccount && (
        <>
          <MobileNumbersGetStarted
            onClose={onClose}
            userAccount={data.userAccount}
          />
        </>
      )}
    </CiroModal>
  );
};

export default MobileNumbersIntegrationModal;
