import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";

import "react-datepicker/dist/react-datepicker.css";
import "./DateRangeFilter.css";
import CiroTextInput from "../shared/CiroTextInput";
import CiroTooltipContainer from "../shared/CiroTooltipContainer";
import { DEFAULT_FILTER_TOOLTIP_TEXT } from "../../utils/consts";

// Have to be optional since the types are coming from Date Picker, not our props
interface IDateInputFieldProps {
  onChange?: () => void;
  onClick?: () => void;
  placeholder?: string;
  value?: string;
}

const DateInputField = forwardRef(
  (
    { placeholder, onClick, value, onChange }: IDateInputFieldProps,
    ref: any,
  ) => {
    return (
      <CiroTextInput
        type="text"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        ref={ref}
        onChange={onChange}
      />
    );
  },
);

interface IDateRangeFilterProps {
  blurred?: boolean;
  blurredTooltip?: string;
  displayDirection?: "horizontal" | "vertical";
  endDate: Date | null;
  endDateLabel?: string;
  endDatePlaceholderText?: string;
  label?: string;
  resetOffset: () => void;
  setEndDate: (v: Date | null) => void;
  setStartDate: (v: Date | null) => void;
  startDate: Date | null;
  startDateLabel?: string;
  startDatePlaceholderText?: string;
}

const DateRangeFilter = ({
  blurred = false,
  blurredTooltip = DEFAULT_FILTER_TOOLTIP_TEXT,
  displayDirection = "horizontal",
  endDate,
  endDateLabel,
  endDatePlaceholderText = "End Date",
  label,
  resetOffset,
  setEndDate,
  setStartDate,
  startDate,
  startDateLabel,
  startDatePlaceholderText = "Start Date",
}: IDateRangeFilterProps) => {
  const isVertical = displayDirection === "vertical";
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-items-start",
      )}
    >
      {label && <div className={classNames("ciro-v1-pb-2")}>{label}</div>}
      <div
        className={classNames(
          "ciro-v1-items-stretch",
          "ciro-v1-w-full",
          "ciro-v1-flex",
          {
            "ciro-v1-space-x-2": !isVertical,
            "ciro-v1-space-y-2": isVertical,
            "ciro-v1-flex-col": isVertical,
          },
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-w-full",
          )}
        >
          {startDateLabel && (
            <div className={classNames("ciro-v1-text-sm")}>
              {startDateLabel}
            </div>
          )}
          <CiroTooltipContainer
            className={classNames("ciro-v1-w-full")}
            tooltip={blurredTooltip}
            disabled={!blurred}
          >
            <div
              className={classNames("ciro-v1-w-full", {
                "ciro-v1-blur-sm": blurred,
                "ciro-v1-pointer-events-none": blurred,
              })}
            >
              <DatePicker
                selected={startDate}
                onChange={(v) => {
                  setStartDate(v);
                  resetOffset();
                }}
                maxDate={endDate}
                placeholderText={startDatePlaceholderText}
                isClearable
                showDisabledMonthNavigation
                customInput={<DateInputField />}
              />
            </div>
          </CiroTooltipContainer>
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-w-full",
          )}
        >
          {endDateLabel && (
            <div className={classNames("ciro-v1-text-sm")}>{endDateLabel}</div>
          )}
          <CiroTooltipContainer
            className={classNames("ciro-v1-w-full")}
            tooltip={blurredTooltip}
            disabled={!blurred}
          >
            <div
              className={classNames("ciro-v1-w-full", {
                "ciro-v1-blur-sm": blurred,
                "ciro-v1-pointer-events-none": blurred,
              })}
            >
              <DatePicker
                selected={endDate}
                onChange={(v) => {
                  setEndDate(v);
                  resetOffset();
                }}
                minDate={startDate}
                placeholderText={endDatePlaceholderText}
                isClearable
                showDisabledMonthNavigation
                customInput={<DateInputField />}
              />
            </div>
          </CiroTooltipContainer>
        </div>
      </div>
    </div>
  );
};

export default DateRangeFilter;
