import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import CiroContainer from "../../components/shared/CiroContainer";
import classNames from "classnames";
import CiroTitle from "../../components/shared/CiroTitle";
import { useState } from "react";
import UploadCSVCollectionNameCard from "../../components/uploadCsv/UploadCSVCollectionNameCard";
import UploadCSVUploadStatus from "../../components/uploadCsv/UploadCSVUploadStatus";
import { gql, useQuery } from "@apollo/client";
import {
  UploadCsv_UserAccountQuery,
  UploadCsv_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import CiroCard from "../../components/shared/CiroCard";
import {
  LinkCRMButton,
  LinkCRMButton_UserAccount,
} from "../../components/link-crm/LinkCRMButton";
import OneSchemaModal from "../../components/uploadCsv/OneSchemaModal";

const UploadCSV_UserAccount = gql`
  query UploadCSV_UserAccount {
    userAccount {
      id
      hasCRMConnection
      ...LinkCRMButton_UserAccount
      org {
        id
        organizationMergeIntegration {
          integration
          last_matched_at
        }
      }
    }
    uploadCSVToCollectionInitializers {
      clientId
      signedUrl
      storageFilePath
      userJwt
    }
  }
  ${LinkCRMButton_UserAccount}
`;

const UploadCSV = () => {
  const [collectionName, setCollectionName] = useState<string>("");
  const [uploadCSVComplete, setUploadCSVComplete] = useState<boolean>(false);
  const [oneSchemaImporterOpen, setOneSchemaImporterOpen] =
    useState<boolean>(false);
  const { data, loading } = useQuery<
    UploadCsv_UserAccountQuery,
    UploadCsv_UserAccountQueryVariables
  >(UploadCSV_UserAccount);

  const hasCRMConnection = Boolean(data?.userAccount?.hasCRMConnection);
  const crmName =
    data?.userAccount?.org?.organizationMergeIntegration?.integration || "CRM";

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <CiroTitle
        title="Import CSV"
        subtitle={
          <div>
            <div>
              Import up to 100,000 rows at a time to match to your {crmName}{" "}
              account. This does not use Ciro credits.
            </div>
            <div>Matched accounts will be added to a new collection.</div>
          </div>
        }
      />
      <div
        className={classNames([
          "ciro-v1-pt-24",
          "ciro-v1-flex",
          "ciro-v1-justify-center",
          "ciro-v1-w-full",
          "ciro-v1-mb-4",
        ])}
      >
        {loading && <Loading size="SMALL" />}
        {!loading && (
          <>
            {!hasCRMConnection && (
              <CiroCard>
                <div className={classNames("ciro-v1-max-w-sm", "ciro-v1-pb-4")}>
                  Creating collections from an imported CSV is available for
                  users who have connected their CRM to Ciro.
                </div>
                <LinkCRMButton userAccount={data?.userAccount} />
              </CiroCard>
            )}
            {hasCRMConnection && (
              <>
                <OneSchemaModal
                  uploadCSVToCollectionInitializers={
                    data?.uploadCSVToCollectionInitializers
                  }
                  isOpen={oneSchemaImporterOpen}
                  setIsOpen={setOneSchemaImporterOpen}
                  onSuccess={() => {
                    setOneSchemaImporterOpen(false);
                    setUploadCSVComplete(true);
                  }}
                />
                {!uploadCSVComplete && data?.userAccount && (
                  <UploadCSVCollectionNameCard
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    setModalOpen={setOneSchemaImporterOpen}
                    modalIsOpen={oneSchemaImporterOpen}
                  />
                )}
                {uploadCSVComplete && (
                  <UploadCSVUploadStatus
                    storageFilePath={
                      data?.uploadCSVToCollectionInitializers
                        ?.storageFilePath || ""
                    }
                    collectionName={collectionName}
                    setCollectionName={setCollectionName}
                    setUploadCSVComplete={setUploadCSVComplete}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </CiroContainer>
  );
};

export default withAuthenticationRequired(UploadCSV, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />,
});
