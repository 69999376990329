import { useAuth0 } from "@auth0/auth0-react";
import { useContext } from "react";
import logo from "./assets/ciro_logo_transparent.png";

import { analytics } from "./utils/vendors";
import AppContext from "./contexts/AppContext";
import classNames from "classnames";
import CiroButton, {
  CiroButtonStyleEnum,
} from "./components/shared/CiroButton";
import CiroContainer from "./components/shared/CiroContainer";

interface IAuthProps {
  error: any;
  getTokenAndTryAgain: () => void;
}

const Auth = ({ error, getTokenAndTryAgain }: IAuthProps) => {
  const { user } = useContext(AppContext);
  const { loginWithRedirect, logout } = useAuth0();

  const login = () =>
    loginWithRedirect({
      appState: {
        target: window.location.href,
      },
    });
  const signUp = () => loginWithRedirect({ screen_hint: "signup" });

  if (error.error === "login_required") {
    analytics.track("Viewed login page");
    return (
      <div
        className={classNames(
          "ciro-v1-h-screen",
          "ciro-v1-flex",
          "ciro-v1-flex-col",
          "ciro-v1-flex-wrap",
          "ciro-v1-justify-center",
          "ciro-v1-content-center",
        )}
      >
        <div className={classNames("ciro-v1-text-slate-600", "hero my-5")}>
          <img
            className="ciro-v1-inline ciro-v1-mb-8"
            src={logo}
            alt="Ciro logo"
            width="180"
          />
          <p className={classNames("ciro-v1-text-xl", "ciro-v1-pb-4")}>
            AI-powered search & enrichment for B2B sales
          </p>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-font-medium",
              "ciro-v1-justify-center",
              "ciro-v1-pb-4",
            )}
          >
            <CiroButton
              customClassName="test-login-button"
              style={CiroButtonStyleEnum.LOUD}
              onClick={login}
              analyticsField="Log in"
            >
              Log In
            </CiroButton>
          </div>
          Need an account?{" "}
          <span
            className={classNames(
              "ciro-v1-underline",
              "ciro-v1-text-blue-600",
              "hover:ciro-v1-text-blue-800",
              "visited:ciro-v1-text-purple-600",
              "ciro-v1-cursor-pointer",
            )}
            onClick={signUp}
          >
            Sign up with work email
          </span>
          .
        </div>
      </div>
    );
  }
  if (error.error === "consent_required") {
    return (
      <div className="ciro-v1-m-12">
        <CiroButton
          onClick={getTokenAndTryAgain}
          analyticsField="Provide consent for access"
        >
          Please provide your consent to access Ciro.
        </CiroButton>
      </div>
    );
  }
  analytics.track("User denied access", {
    user: user?.email,
  });
  const errorMessage =
    error.message === "no_signups_from_free_domain"
      ? "To prevent abuse, free emails such as, but not limited to, Hotmail, Gmail, Yahoo and AOL are not allowed."
      : "You don't have access to this application.";
  return (
    <CiroContainer>
      <div className="ciro-v1-flex ciro-v1-col ciro-v1-justify-center">
        <div className="ciro-v1-text-center hero mt-5 ciro-v1-col ciro-v1-items-center">
          <p className="ciro-v1-text-xl ciro-v1-font-light mt-8">
            {errorMessage}
          </p>
          <p className="ciro-v1-text-xl ciro-v1-font-light">
            If you believe this is in error, please contact help@ciro.io.
          </p>
          <p
            className={classNames(
              "ciro-v1-text-xl",
              "ciro-v1-font-light",
              "ciro-v1-flex",
              "ciro-v1-flex-col",
              "ciro-v1-items-center",
              "ciro-v1-pt-4",
            )}
          >
            <CiroButton
              onClick={() => logout({ returnTo: window.location.origin })}
              analyticsField="Log out and try again"
            >
              Log out and try again
            </CiroButton>
          </p>
        </div>
      </div>
    </CiroContainer>
  );
};

export default Auth;
