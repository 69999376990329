import classNames from "classnames";
import { gql } from "@apollo/client";
import {
  CreditPlanEnum,
  Maybe,
  ProfileCompanyCreditsCard_OrganizationFragment,
} from "../../__generated__/graphql";
import NumberFormat from "react-number-format";
import CoinIcon from "../../assets/img/icons/CoinIcon";
import CiroLink from "../shared/CiroLink";
import { PRICING_PAGE_LINK } from "../../utils/consts";
import LinkArrowIcon from "../../assets/img/icons/LinkArrowIcon";
import CiroCoinPill from "../shared/CiroCoinPill";

export const ProfileCompanyCreditsCard_Organization = gql`
  fragment ProfileCompanyCreditsCard_Organization on Organization {
    credit_plan {
      incremental_credits
      plan
      balance
    }
  }
`;

const CREDITS_PLAN_TO_SCHEDULE = {
  [CreditPlanEnum.Annual]: "annually",
  [CreditPlanEnum.Monthly]: "monthly",
  [CreditPlanEnum.NonRecurring]:
    "one time (please contact the Ciro team to reload more credits)",
  [CreditPlanEnum.Quarterly]: "quarterly",
};

interface IProfileCompanyCreditsCardProps {
  organization?: Maybe<ProfileCompanyCreditsCard_OrganizationFragment>;
}

const ProfileCompanyCreditsCard = ({
  organization,
}: IProfileCompanyCreditsCardProps) => {
  if (!organization?.credit_plan) {
    return null;
  }

  const creditPlan = organization.credit_plan;

  return (
    <div>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-text-sm",
          "ciro-v1-items-center",
          "ciro-v1-pb-4",
        )}
      >
        <div className={classNames("ciro-v1-font-semibold", "ciro-v1-pr-2")}>
          Credits
        </div>
        <CiroCoinPill amount={creditPlan.balance || 0} />
      </div>
      <div
        className={classNames(
          "ciro-v1-border-t-1",
          "-ciro-v1-mx-4",
          "ciro-v1-p-4",
          "ciro-v1-bg-neutral-50",
          "ciro-v1-rounded-b-xl",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-font-semibold",
            "ciro-v1-text-sm",
            "ciro-v1-pb-2",
          )}
        >
          Credits schedule
        </div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-text-sm",
          )}
        >
          <div className={classNames("ciro-v1-text-amber-500", "ciro-v1-pr-2")}>
            <CoinIcon />
          </div>
          +
          <NumberFormat
            displayType="text"
            value={creditPlan.incremental_credits}
            thousandSeparator=","
          />
          &nbsp;new credits given{" "}
          {
            CREDITS_PLAN_TO_SCHEDULE[
              creditPlan.plan || CreditPlanEnum.NonRecurring
            ]
          }
        </div>
        <div className={classNames("ciro-v1-mt-2")}>
          <CiroLink href={PRICING_PAGE_LINK}>
            <div
              className={classNames(
                "ciro-v1-flex",
                "ciro-v1-items-center",
                "ciro-v1-text-sm",
              )}
            >
              <span>Upgrade your account</span>
              <span className="ciro-v1-pl-2">
                <LinkArrowIcon />
              </span>
            </div>
          </CiroLink>
        </div>
      </div>
    </div>
  );
};

export default ProfileCompanyCreditsCard;
