import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

const MobileNumbersGetStartedContainer = () => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-justify-center",
        "ciro-v1-items-center",
        "ciro-v1-w-full",
        "ciro-v1-pt-32",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-max-w-md",
          "ciro-v1-flex-col",
          "ciro-v1-justify-center",
          "ciro-v1-items-center",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-center",
            "ciro-v1-mb-8",
          )}
        >
          <div
            className={classNames(
              "ciro-v1-bg-white",
              "ciro-v1-rounded-full",
              "ciro-v1-text-orange-500",
              "ciro-v1-w-fit",
              "ciro-v1-p-2",
            )}
          >
            <ArchiveBoxIcon
              className={classNames("ciro-v1-w-6", "ciro-v1-h-6")}
            />
          </div>
        </div>
        <div
          className={classNames(
            "ciro-v1-text-center",
            "ciro-v1-text-xl",
            "ciro-v1-font-bold",
            "ciro-v1-pb-2",
          )}
        >
          Enrich Phone Data via Waterfall
        </div>
        <div
          className={classNames(
            "ciro-v1-text-center",
            "ciro-v1-text-sm",
            "ciro-v1-text-zinc-500",
          )}
        >
          Ciro pulls from all of the world's best mobile phone data providers.
          Use our Chrome extension to find the right number for your contacts,
          or connect your CRM to automatically replace wrong numbers.
        </div>
      </div>
    </div>
  );
};

export default MobileNumbersGetStartedContainer;
