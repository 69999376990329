import { ApolloError } from "@apollo/client";
import {
  EnrichmentStepCard_EnrichmentStepQuery,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import { IUpsertEnrichmentStepVariables } from "../EnrichmentStepCard";
import EnrichmentStepTechniqueChatGptCard from "./EnrichmentStepTechniqueChatGptCard";
import EnrichmentStepTechniqueCoalesceCard from "./EnrichmentStepTechniqueCoalesceCard";
import EnrichmentStepTechniqueFindCiroCompanyCard from "./EnrichmentStepTechniqueFindCiroCompanyCard";
import EnrichmentStepTechniqueFindEmailCard from "./EnrichmentStepTechniqueFindEmailCard";
import EnrichmentStepTechniqueReturnValueCard from "./EnrichmentStepTechniqueReturnValueCard";
import EnrichmentStepTechniqueSerpApiCard from "./EnrichmentStepTechniqueSerpApiCard";
import EnrichmentStepTechniquePhoneSurveyCard from "./EnrichmentStepTechniquePhoneSurveyCard";
import EnrichmentStepTechniqueLlmWebEnrichmentCard from "./EnrichmentStepTechniqueLlmWebEnrichmentCard";
import EnrichmentStepTechniqueGetContactCard from "./EnrichmentStepTechniqueGetContactCard";
import EntichmentStepTechniqueFindPhoneCard from "./EnrichmentStepTechniqueFindPhoneCard";

export interface IEnrichmentStepTechniqueCardProps {
  confirmUpdateEnrichmentStep: ({
    data,
  }: {
    data: IUpsertEnrichmentStepVariables;
  }) => void;
  enrichmentStep: EnrichmentStepCard_EnrichmentStepQuery["enrichmentStep"];
  onClose: () => void;
  loading: boolean;
  error: ApolloError | undefined;
}

const chooseEnrichmentStepTechniqueCard = (
  enrichmentTechnique: EnrichmentTechniqueEnum | null,
) => {
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.ChatGpt:
      return EnrichmentStepTechniqueChatGptCard;
    case EnrichmentTechniqueEnum.Coalesce:
      return EnrichmentStepTechniqueCoalesceCard;
    case EnrichmentTechniqueEnum.FindCiroCompany:
      return EnrichmentStepTechniqueFindCiroCompanyCard;
    case EnrichmentTechniqueEnum.FindEmail:
      return EnrichmentStepTechniqueFindEmailCard;
    case EnrichmentTechniqueEnum.FindPhone:
      return EntichmentStepTechniqueFindPhoneCard;
    case EnrichmentTechniqueEnum.LlmWebResearch:
      return EnrichmentStepTechniqueLlmWebEnrichmentCard;
    case EnrichmentTechniqueEnum.ReturnValue:
      return EnrichmentStepTechniqueReturnValueCard;
    case EnrichmentTechniqueEnum.SerpApi:
      return EnrichmentStepTechniqueSerpApiCard;
    case EnrichmentTechniqueEnum.PhoneSurvey:
      return EnrichmentStepTechniquePhoneSurveyCard;
    case EnrichmentTechniqueEnum.FindContact:
      return EnrichmentStepTechniqueGetContactCard;
    default:
      return null;
  }
};

export default chooseEnrichmentStepTechniqueCard;
