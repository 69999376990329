const LinkSlashIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <path
            id="Path"
            d="M14.2542 10.45L16.0767 8.62745C17.4192 7.28495 17.4192 5.10829 16.0767 3.76579V3.76579C14.7342 2.42329 12.5576 2.42329 11.2151 3.76579L9.39258 5.58829"
            stroke="#525252"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_2"
            d="M6.875 12.9675L8.4375 11.405"
            stroke="#525252"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_3"
            d="M11.5625 8.28003L13.125 6.71753"
            stroke="#525252"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_4"
            d="M15.8592 15.7016L4.14087 3.98328"
            stroke="#525252"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Path_5"
            d="M5.74588 9.23499L3.92338 11.0575C2.58088 12.4 2.58088 14.5767 3.92338 15.9192V15.9192C5.26588 17.2617 7.44255 17.2617 8.78505 15.9192L10.6075 14.0967"
            stroke="#525252"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default LinkSlashIcon;
