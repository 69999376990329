import { useAuth0 } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/shared/Loading";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ProfileCompanyCreditsCard_OrganizationFragmentDoc,
  ProfileCreditsSummary_OrganizationFragmentDoc,
  Profile_RequestChangePasswordEmailMutation,
  Profile_RequestChangePasswordEmailMutationVariables,
  Profile_UserAccountQuery,
  Profile_UserAccountQueryVariables,
} from "../../__generated__/graphql";
import {
  LinkCRMButton,
  LinkCRMButton_UserAccount,
} from "../../components/link-crm/LinkCRMButton";
import CiroContainer from "../../components/shared/CiroContainer";
import classNames from "classnames";
import CiroCard from "../../components/shared/CiroCard";
import ProfilePhotoIcon from "../../assets/img/icons/ProfilePhotoIcon";
import CiroButton from "../../components/shared/CiroButton";
import ProfileCompanyCreditsCard, {
  ProfileCompanyCreditsCard_Organization,
} from "../../components/profile/ProfileCompanyCreditsCard";
import { useFragment } from "../../__generated__";
import SkeletonLoading from "../../components/shared/SkeletonLoading";
import ProfileCreditsSummary, {
  ProfileCreditsSummary_Organization,
} from "../../components/profile/ProfileCreditsSummary";
import { startOfMonth, subMonths } from "date-fns";

const Profile_requestChangePasswordEmail = gql`
  mutation Profile_requestChangePasswordEmail {
    requestChangePasswordEmail {
      success
    }
  }
`;

const Profile_UserAccount = gql`
  query Profile_UserAccount($creditTransactionsAfter: Date) {
    userAccount {
      id
      hasEmailPasswordLogin
      impersonating {
        email
      }
      org {
        ...ProfileCompanyCreditsCard_Organization
        ...ProfileCreditsSummary_Organization
      }
      ...LinkCRMButton_UserAccount
    }
  }
  ${LinkCRMButton_UserAccount}
  ${ProfileCompanyCreditsCard_Organization}
  ${ProfileCreditsSummary_Organization}
`;

const Profile = () => {
  const { user } = useAuth0();

  const [
    requestChangePasswordEmail,
    { data: requestPasswordChangeData, loading: requestPasswordChangeLoading },
  ] = useMutation<
    Profile_RequestChangePasswordEmailMutation,
    Profile_RequestChangePasswordEmailMutationVariables
  >(Profile_requestChangePasswordEmail);

  const {
    data: userAccountData,
    loading: userAccountLoading,
    refetch: userAccountRefetch,
  } = useQuery<Profile_UserAccountQuery, Profile_UserAccountQueryVariables>(
    Profile_UserAccount,
    {
      variables: {
        creditTransactionsAfter: startOfMonth(subMonths(new Date(), 3)),
      },
    },
  );

  const profileCompanyCreditsCardOrg = useFragment(
    ProfileCompanyCreditsCard_OrganizationFragmentDoc,
    userAccountData?.userAccount?.org,
  );

  const profileCreditsSummaryOrg = useFragment(
    ProfileCreditsSummary_OrganizationFragmentDoc,
    userAccountData?.userAccount?.org,
  );

  // Has an email password login and has not already requested recently
  const canRequestChangePassword =
    userAccountData?.userAccount?.hasEmailPasswordLogin &&
    !requestPasswordChangeData?.requestChangePasswordEmail.success;

  return (
    <>
      <CiroContainer
        className={classNames([
          "ciro-v1-flex",
          "ciro-v1-items-center",
          "ciro-v1-bg-zinc-100",
        ])}
      >
        <div
          className={classNames([
            "ciro-v1-pt-24",
            "ciro-v1-flex",
            "ciro-v1-justify-center",
            "ciro-v1-w-full",
            "ciro-v1-mb-4",
          ])}
        >
          <div className={classNames(["ciro-v1-w-fit", "ciro-v1-pb-4"])}>
            <CiroCard>
              {userAccountLoading ? (
                <div className={classNames("ciro-v1-w-96")}>
                  <SkeletonLoading numSkeletons={2} skeletonHeight={"16rem"} />
                </div>
              ) : (
                <div>
                  <div
                    className={classNames([
                      "ciro-v1-flex",
                      "ciro-v1-items-start",
                    ])}
                  >
                    <div className={classNames("ciro-v1-pb-2")}>
                      <ProfilePhotoIcon />
                    </div>
                    <div className={classNames("ciro-v1-px-6")}>
                      <div
                        className={classNames(
                          "ciro-v1-font-semibold",
                          "ciro-v1-text-2xl",
                        )}
                      >
                        {user?.nickname}
                      </div>
                      <div className={classNames("ciro-v1-mt-1")}>
                        {user?.email}
                      </div>
                      <div>
                        <LinkCRMButton
                          userAccount={userAccountData?.userAccount}
                          onTokenStored={userAccountRefetch}
                        />
                      </div>
                    </div>
                    {userAccountData?.userAccount?.impersonating && (
                      <div
                        className={classNames(
                          "ciro-v1-mt-4",
                          "ciro-v1-text-center",
                        )}
                      >
                        Impersonating{" "}
                        {userAccountData?.userAccount?.impersonating.email}
                      </div>
                    )}
                    <div
                      className={classNames([
                        "ciro-v1-flex-col",
                        "ciro-v1-flex",
                        "ciro-v1-items-center",
                      ])}
                    >
                      {canRequestChangePassword && (
                        <CiroButton
                          onClick={() => requestChangePasswordEmail()}
                          disabled={requestPasswordChangeLoading}
                          analyticsField="Reset Password"
                        >
                          Reset Password
                        </CiroButton>
                      )}
                      {requestPasswordChangeData?.requestChangePasswordEmail
                        .success && (
                        <CiroButton
                          disabled={true}
                          analyticsField="Request Password (SHOULD NEVER SEE IN SEGMENT)"
                        >
                          Password request sent to your email
                        </CiroButton>
                      )}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      "ciro-v1-border-t-1",
                      "-ciro-v1-mx-8",
                      "-ciro-v1-mb-12",
                      "ciro-v1-p-4",
                    )}
                  >
                    <ProfileCompanyCreditsCard
                      organization={profileCompanyCreditsCardOrg}
                    />
                  </div>
                </div>
              )}
            </CiroCard>
            <div className={classNames("ciro-v1-pt-8")}>
              <ProfileCreditsSummary org={profileCreditsSummaryOrg} />
            </div>
          </div>
        </div>
      </CiroContainer>
      <footer
        className={classNames(
          "ciro-v1-bg-gray-100",
          "ciro-v1-bottom-0",
          "ciro-v1-content-center",
          "ciro-v1-flex-col",
          "ciro-v1-flex",
          "ciro-v1-w-full",
          "lg:ciro-v1-hidden",
        )}
      >
        <div className="ciro-v1-flex ciro-v1-justify-evenly ciro-v1-py-4 ciro-v1-border-t-2 ciro-v1-border-t-stone-200">
          <a
            className="ciro-v1-no-underline ciro-v1-my-1 ciro-v1-text-stone-300 ciro-v1-text-xs hover:ciro-v1-text-neutral-600"
            href="https://www.ciro.io/terms-of-service"
          >
            Terms of service
          </a>
          <a
            className="ciro-v1-no-underline ciro-v1-my-1 ciro-v1-text-stone-300 ciro-v1-text-xs hover:ciro-v1-text-neutral-600"
            href="https://www.ciro.io/privacy-policy"
          >
            Privacy policy
          </a>
        </div>
      </footer>
    </>
  );
};

export default withAuthenticationRequired(Profile, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => <Loading />,
});
