import { CustomCellRendererProps } from "@ag-grid-community/react";
import ExecutionCell_CrmAccount from "./ExecutionCell_CRMAccount";
import ExecutionCell_Default from "./ExecutionCell_Default";
import ExecutionCell_SerpApi from "./ExecutionCell_SerpApi";
import ExecutionCell_FlatCompany from "./ExecutionCell_FlatCompany";
import { ICellRendererParams } from "@ag-grid-community/core";
import { EnrichmentTechniqueEnum } from "../../../../__generated__/graphql";
import ExecutionCell_FooterCount from "./ExecutionCell_FooterCount";
import SkeletonLoading from "../../../shared/SkeletonLoading";
import ExecutionCell_ManualWebResearch from "./ExecutionCell_ManualWebResearch";
import ExecutionCell_PhoneSurvey from "./ExecutionCell_PhoneSurvey";
import ExecutionCell_Error from "./ExecutionCell_Error";
import ExecutionCell_NoData from "./ExecutionCell_NoData";
import ExecutionCell_GetContact from "./ExecutionCell_GetContact";
import JsonIcon from "../../../../assets/img/icons/JsonIcon";
import classNames from "classnames";
import { useRef } from "react";
import { AG_GRID_ROW_HEIGHT } from "../EnrichmentFlowTableContainer";
import ExecutionCell_GetEmail from "./ExecutionCell_GetEmail";

export interface IEnrichmentExecutionValue {
  output: any;
  error?: string | null;
  flowRowId?: number;
}

interface IChooseEnrichmentExecutionCellProps {
  enrichmentTechnique: EnrichmentTechniqueEnum | null;
  value: IEnrichmentExecutionValue | null;
}

const chooseEnrichmentExecutionCell = ({
  enrichmentTechnique,
  value,
}: IChooseEnrichmentExecutionCellProps) => {
  if (value?.error) {
    return ExecutionCell_Error;
  }
  if (value?.output === "null" || value?.output === "[]") {
    return ExecutionCell_NoData;
  }
  switch (enrichmentTechnique) {
    case EnrichmentTechniqueEnum.FindCrmAccount:
      return ExecutionCell_CrmAccount;
    case EnrichmentTechniqueEnum.SerpApi:
      return ExecutionCell_SerpApi;
    case EnrichmentTechniqueEnum.FindCiroCompany:
    case EnrichmentTechniqueEnum.InitializeCompany:
      return ExecutionCell_FlatCompany;
    case EnrichmentTechniqueEnum.ManualWebResearch:
      return ExecutionCell_ManualWebResearch;
    case EnrichmentTechniqueEnum.PhoneSurvey:
      return ExecutionCell_PhoneSurvey;
    case EnrichmentTechniqueEnum.FindContact:
      return ExecutionCell_GetContact;
    case EnrichmentTechniqueEnum.FindEmail:
      return ExecutionCell_GetEmail;
    default:
      return ExecutionCell_Default;
  }
};

export interface IEnrichmentExecutionCellProps extends CustomCellRendererProps {
  enrichmentTechnique: EnrichmentTechniqueEnum | null;
  isLoadingCol: boolean;
  params: ICellRendererParams;
  value: IEnrichmentExecutionValue | null;
  setCellDetailsValue: (value: any) => void;
  selectedInput: string | null;
  loadingRowIds: Set<number> | null;
}

const EnrichmentExecutionCell = (props: IEnrichmentExecutionCellProps) => {
  const {
    value,
    enrichmentTechnique,
    params,
    isLoadingCol,
    loadingRowIds,
    setCellDetailsValue,
  } = props;

  const childRef = useRef<HTMLDivElement>(null);

  const jsonTechniques = [
    EnrichmentTechniqueEnum.FindContact,
    EnrichmentTechniqueEnum.FindCrmAccount,
    EnrichmentTechniqueEnum.InitializeCompany,
    EnrichmentTechniqueEnum.SerpApi,
  ];

  const handleJsonIconClick = () => {
    // Trigger a click on the EnrichmentTechniqueExecutionCell
    const parsedValue = value?.output && JSON.parse(value.output);
    setCellDetailsValue(parsedValue);
  };

  const isLoading =
    isLoadingCol &&
    (loadingRowIds == null || loadingRowIds.has(value?.flowRowId || -1));

  if (params.node.rowPinned === "bottom") {
    // eslint-disable-next-line react/jsx-pascal-case
    return <ExecutionCell_FooterCount {...props} />;
  }

  if (isLoading) {
    return (
      <SkeletonLoading
        numSkeletons={1}
        skeletonHeight="20px"
        ignoreDefaultPadding={true}
      />
    );
  }

  const EnrichmentTechniqueExecutionCell = chooseEnrichmentExecutionCell({
    enrichmentTechnique,
    value,
  });

  const showJson =
    enrichmentTechnique &&
    jsonTechniques.includes(enrichmentTechnique) &&
    Boolean(childRef?.current?.textContent?.length);

  return (
    <div
      className={classNames(
        { "ciro-v1-flex": showJson },
        { "ciro-v1-flex-row": showJson },
        { "ciro-v1-items-center": showJson },
      )}
    >
      {showJson && (
        <div
          className={classNames(
            "ciro-v1-min-w-5",
            "ciro-v1-text-neutral-400",
            "ciro-v1-mr-1",
          )}
          onClick={handleJsonIconClick}
        >
          <JsonIcon />
        </div>
      )}
      <div
        ref={childRef}
        className={classNames("ciro-v1-overflow-hidden")}
        style={{ maxHeight: `${AG_GRID_ROW_HEIGHT}px` }}
      >
        <EnrichmentTechniqueExecutionCell {...props} />
      </div>
    </div>
  );
};

export default EnrichmentExecutionCell;
