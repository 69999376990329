import { useEffect, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import classNames from "classnames";
import {
  AdminViewCreditPlanDetails_OrganizationFragment,
  AdminViewCreditPlanDetails_CreditPlanQuery,
  AdminViewCreditPlanDetails_CreditPlanQueryVariables,
  CreditPlanEnum,
  Maybe,
  AdminViewCreditPlanDetails_CreateCreditTransactionMutation,
  AdminViewCreditPlanDetails_CreateCreditTransactionMutationVariables,
  CreditTransactionMethodEnum,
  AdminViewCreditPlanDetails_DestroyCreditTransactionMutation,
  AdminViewCreditPlanDetails_DestroyCreditTransactionMutationVariables,
} from "../../../__generated__/graphql";
import CiroTextInput from "../../shared/CiroTextInput";
import NumberFormat from "react-number-format";
import CiroButton, { CiroButtonStyleEnum } from "../../shared/CiroButton";
import { ArrowUturnLeftIcon, PlusIcon } from "@heroicons/react/20/solid";
import { CREDIT_PLAN_TO_CYCLE_FORMATTING } from "./AdminViewOrganizationDetails";
import {
  AdminOrganizationDetails_SelectedOrganization,
  OrganizationDetailsModalStateEnum,
} from "./AdminOrganizationDetails";
import Loading from "../../shared/Loading";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CiroDropDown from "../../shared/CiroDropdown";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import CiroTableHeader from "../../shared/CiroTable/CiroTableHeader";
import CiroTableCell from "../../shared/CiroTable/CiroTableCell";
import { formatDate } from "../../../utils/formatters";
import toast from "react-hot-toast";
import CiroSpinner from "../../shared/CiroSpinner";

const AdminViewCreditPlanDetailsSchema = yup.object({
  creditTransaction: yup
    .object({
      creditOrDebit: yup
        .string()
        .oneOf(Object.values(CreditTransactionMethodEnum)),
      notes: yup.string().required("Please enter a note"),
      amount: yup.number().required("Please enter an amount"),
    })
    .required()
    .test(
      "amount-must-match-creditOrDebit",
      "Use a negative number if crediting to the plan, a positive number if debiting",
      (value) => {
        return value.creditOrDebit === CreditTransactionMethodEnum.Credit
          ? value.amount < 0
          : value.amount > 0;
      },
    ),
});

export const AdminViewCreditPlanDetails_Organization = gql`
  fragment AdminViewCreditPlanDetails_Organization on Organization {
    ciro_id
    name
    credit_plan {
      id
      incremental_credits
      plan
      balance
    }
  }
`;

const AdminViewCreditPlanDetails_CreditPlan = gql`
  query AdminViewCreditPlanDetails_CreditPlan($orgCiroId: Int) {
    organization(ciro_id: $orgCiroId) {
      credit_plan {
        credit_transactions {
          id {
            value
            populated
          }
          user {
            email
          }
          notes
          amount
          method
          created_at
        }
      }
    }
  }
`;

const AdminViewCreditPlanDetails_CreateCreditTransaction = gql`
  mutation AdminViewCreditPlanDetails_CreateCreditTransaction(
    $amount: Int!
    $creditPlanId: Int!
    $creditOrDebit: CreditTransactionMethodEnum
    $notes: String
  ) {
    createCreditTransaction(
      amount: $amount
      creditPlanId: $creditPlanId
      method: $creditOrDebit
      notes: $notes
    ) {
      success
    }
  }
`;

const AdminViewCreditPlanDetails_DestroyCreditTransaction = gql`
  mutation AdminViewCreditPlanDetails_DestroyCreditTransaction($transactionId: Int!) {
    destroyTransaction(transactionId: $transactionId) {
      success
    }
  }
`;

interface IAdminViewCreditPlanDetailsProps {
  organization?: Maybe<AdminViewCreditPlanDetails_OrganizationFragment>;
  setModalState: (v: OrganizationDetailsModalStateEnum) => void;
  modalState: OrganizationDetailsModalStateEnum;
}

const AdminViewCreditPlanDetails = ({
  organization,
  setModalState,
  modalState,
}: IAdminViewCreditPlanDetailsProps) => {
  const [addingNewLineItem, setAddingNewLineItem] = useState(false);
  const [deletingTransactionId, setDeletingTransactionId] = useState<number | null>(null);
  const [isDeletingTransaction, setIsDeletingTransaction] = useState(false);
  const [
    fetchcreditTransactionDetails,
    { data: fetchedCreditDetails, loading: loadingFetchedCreditDetails },
  ] = useLazyQuery<
    AdminViewCreditPlanDetails_CreditPlanQuery,
    AdminViewCreditPlanDetails_CreditPlanQueryVariables
  >(AdminViewCreditPlanDetails_CreditPlan, {
    notifyOnNetworkStatusChange: true,
  });

  const [
    createCreditTransaction,
    {
      data: createCreditTransactionData,
      reset: resetCreateCreditTransactionData,
    },
  ] = useMutation<
    AdminViewCreditPlanDetails_CreateCreditTransactionMutation,
    AdminViewCreditPlanDetails_CreateCreditTransactionMutationVariables
  >(AdminViewCreditPlanDetails_CreateCreditTransaction);

  const [destroyCreditTransaction] = useMutation<
    AdminViewCreditPlanDetails_DestroyCreditTransactionMutation,
    AdminViewCreditPlanDetails_DestroyCreditTransactionMutationVariables
  >(AdminViewCreditPlanDetails_DestroyCreditTransaction);

  useEffect(() => {
    if (modalState === OrganizationDetailsModalStateEnum.CREDIT_PLAN_DETAILS) {
      fetchcreditTransactionDetails({
        variables: {
          orgCiroId: organization?.ciro_id,
        },
      });
    }
  }, [fetchcreditTransactionDetails, modalState, organization?.ciro_id]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(AdminViewCreditPlanDetailsSchema),
    defaultValues: {
      creditTransaction: {
        amount: 100,
        creditOrDebit: CreditTransactionMethodEnum.Debit,
        notes: "",
      },
    },
  });

  const [creditOrDebit] = useWatch({
    control,
    name: ["creditTransaction.creditOrDebit"],
  });

  useEffect(() => {
    if (createCreditTransactionData?.createCreditTransaction.success) {
      resetCreateCreditTransactionData();
      resetForm();
      setAddingNewLineItem(false);
    }
  }, [
    createCreditTransactionData?.createCreditTransaction.success,
    resetCreateCreditTransactionData,
    resetForm,
  ]);

  const onSubmit = async (data: any) => {
    await createCreditTransaction({
      variables: {
        creditPlanId: Number(organization?.credit_plan?.id),
        amount: data.creditTransaction.amount,
        creditOrDebit: data.creditTransaction.creditOrDebit,
        notes: data.creditTransaction.notes,
      },
      refetchQueries: [
        {
          query: AdminViewCreditPlanDetails_CreditPlan,
          variables: {
            orgCiroId: organization?.ciro_id,
          },
          notifyOnNetworkStatusChange: true,
        },
        {
          query: AdminOrganizationDetails_SelectedOrganization,
          variables: {
            organizationCiroId: organization?.ciro_id,
          },
          notifyOnNetworkStatusChange: true,
        },
      ],
    });
  };

  const startDeletingCreditTransaction = (transactionId: number) => {
    setDeletingTransactionId(transactionId);
  };

  const confirmDeleteCreditTransaction = async () => {
    if (deletingTransactionId) {
      setIsDeletingTransaction(true);
      try {
        await destroyCreditTransaction({
          variables: { transactionId: deletingTransactionId },
          refetchQueries: [
            {
              query: AdminViewCreditPlanDetails_CreditPlan,
              variables: { orgCiroId: organization?.ciro_id },
            },
            {
              query: AdminOrganizationDetails_SelectedOrganization,
              variables: { organizationCiroId: organization?.ciro_id },
            },
          ],
        });
        setDeletingTransactionId(null);
        toast.success("Transaction deleted successfully");
      } catch (error) {
        console.error("Error deleting credit transaction:", error);
        toast.error("Error deleting credit transaction — no changes were made");
      } finally {
        setIsDeletingTransaction(false);
      }
    }
  };

  const cancelDeleteCreditTransaction = () => {
    setDeletingTransactionId(null);
  };

  if (!organization) {
    return null;
  }

  const creditPlan = organization.credit_plan;

  if (!creditPlan) {
    return (
      <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
        <div className={classNames("ciro-v1-font-medium", "ciro-v1-text-lg")}>
          No credit plan found for {organization.name}
        </div>
        <CiroButton
          analyticsField={"Credit Plan Return to Org Details"}
          onClick={() => {
            setModalState(OrganizationDetailsModalStateEnum.VIEW);
          }}
        >
          <ArrowUturnLeftIcon />
        </CiroButton>
      </div>
    );
  }

  const credit_transactions =
    fetchedCreditDetails?.organization?.credit_plan?.credit_transactions;

  return (
    <div>
      <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
        <div className={classNames("ciro-v1-font-medium", "ciro-v1-text-lg")}>
          {organization.name} Credits Plan
          <span
            className={classNames("ciro-v1-pl-4", {
              "ciro-v1-text-rose-500": (creditPlan.balance || 0) <= 0,
            })}
          >
            <NumberFormat
              value={creditPlan.balance}
              thousandSeparator={true}
              displayType="text"
            />
            &nbsp;credits remaining
          </span>
        </div>
        <CiroButton
          style={CiroButtonStyleEnum.UNSTYLED}
          analyticsField={"return to org details from credit plan"}
          onClick={() => {
            setModalState(OrganizationDetailsModalStateEnum.VIEW);
          }}
        >
          <ArrowUturnLeftIcon
            className={classNames(["ciro-v1-h-5 w-5"])}
            aria-hidden="true"
          />
        </CiroButton>
      </div>
      <div
        className={classNames(
          "ciro-v1-pt-4",
          "ciro-v1-grid",
          "ciro-v1-grid-cols-1",
          "ciro-v1-gap-4",
          "md:ciro-v1-grid-cols-2",
        )}
      >
        <CiroTextInput
          label="New Credit Cycle"
          value={String(creditPlan.plan)}
          disabled={true}
        />
        {creditPlan.plan && creditPlan.plan !== CreditPlanEnum.NonRecurring && (
          <CiroTextInput
            label={`Number of credits per ${CREDIT_PLAN_TO_CYCLE_FORMATTING[creditPlan.plan]}`}
            value={String(creditPlan.incremental_credits) || "0"}
            disabled={true}
          />
        )}
      </div>
      <div
        className={classNames(
          "ciro-v1-py-4",
          "ciro-v1-grid",
          "ciro-v1-grid-cols-1",
          "ciro-v1-gap-4",
          "md:ciro-v1-grid-cols-2",
        )}
      >
        <div
          className={classNames(
            "ciro-v1-font-medium",
            "ciro-v1-flex",
            "ciro-v1-items-center",
          )}
        >
          Credit Transactions
          {!addingNewLineItem && (
            <CiroButton
              analyticsField="Clicked adding credit line"
              customClassName="ciro-v1-ml-4"
              onClick={() => setAddingNewLineItem(true)}
            >
              <PlusIcon
                className={classNames("ciro-v1-h-5 w-5")}
                aria-hidden="true"
              />
            </CiroButton>
          )}
        </div>
        <div />
        {addingNewLineItem && (
          <>
            <CiroTextInput
              label="Number of credits"
              register={register("creditTransaction.amount")}
              error={
                errors.creditTransaction?.message ||
                errors.creditTransaction?.amount?.message
              }
            />
            <CiroDropDown
              placeholder="Select Organization"
              label="Method"
              value={creditOrDebit}
              options={Object.values(CreditTransactionMethodEnum).map(
                (logMethod) => {
                  return {
                    label: logMethod,
                    value: logMethod,
                  };
                },
              )}
              error={errors.creditTransaction?.creditOrDebit?.message}
              onChange={(v) => {
                setValue("creditTransaction.creditOrDebit", v);
              }}
            />
            <CiroTextInput
              label="Note"
              register={register("creditTransaction.notes")}
              error={errors.creditTransaction?.notes?.message}
            />
            <div className={classNames("ciro-v1-flex", "ciro-v1-items-end")}>
              <span>
                <CiroButton
                  style={CiroButtonStyleEnum.LOUD}
                  analyticsField="Submitting New Line Item"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </CiroButton>
              </span>
              <span className={classNames("ciro-v1-pl-4")}>
                <CiroButton
                  analyticsField="Submitting New Line Item"
                  onClick={() => setAddingNewLineItem(false)}
                >
                  Cancel
                </CiroButton>
              </span>
            </div>
          </>
        )}
      </div>
      {!loadingFetchedCreditDetails && !credit_transactions?.length && (
        <div>No transactions found</div>
      )}
      {loadingFetchedCreditDetails && <Loading size="SMALL" />}

      {!loadingFetchedCreditDetails && Boolean(credit_transactions?.length) && (
        <div
          className={classNames(
            "ciro-v1-max-h-half-screen-ht",
            "ciro-v1-overflow-scroll",
          )}
        >
          <CiroTable>
            <thead className={classNames(["ciro-v1-table-header-group"])}>
              <CiroTableRow clickable={false}>
                <CiroTableHeader>Debit</CiroTableHeader>
                <CiroTableHeader>Credit</CiroTableHeader>
                <CiroTableHeader>User</CiroTableHeader>
                <CiroTableHeader>Note</CiroTableHeader>
                <CiroTableHeader>Date</CiroTableHeader>
                <CiroTableHeader>Delete</CiroTableHeader>
              </CiroTableRow>
            </thead>
            <tbody className={classNames(["ciro-v1-table-row-group"])}>
              {credit_transactions!.map((creditTransaction) => {
                return (
                  <CiroTableRow
                    clickable={false}
                    key={creditTransaction.id?.value}
                  >
                    <CiroTableCell>
                      {creditTransaction.method ===
                        CreditTransactionMethodEnum.Debit && (
                        <span
                          className={classNames(["ciro-v1-text-green-600"])}
                        >
                          +{creditTransaction.amount}
                        </span>
                      )}
                    </CiroTableCell>
                    <CiroTableCell>
                      {creditTransaction.method ===
                        CreditTransactionMethodEnum.Credit && (
                        <span className={classNames(["ciro-v1-text-rose-500"])}>
                          {creditTransaction.amount}
                        </span>
                      )}
                    </CiroTableCell>
                    <CiroTableCell>
                      {creditTransaction.user?.email || "UNKNOWN"}
                    </CiroTableCell>
                    <CiroTableCell>{creditTransaction.notes}</CiroTableCell>
                    <CiroTableCell>
                      {formatDate(creditTransaction.created_at)}
                    </CiroTableCell>
                    <CiroTableCell>
                      {deletingTransactionId ===
                      (creditTransaction.id?.value) ? (
                        <div
                          className={classNames(
                            "ciro-v1-flex",
                            "ciro-v1-flex-col",
                            "ciro-v1-gap-1",
                            "ciro-v1-items-start",
                          )}
                        >
                          {isDeletingTransaction ? (
                            <CiroSpinner loading={isDeletingTransaction} />
                          ) : (
                            <>
                              <CiroButton
                                analyticsField="Confirm Delete Transaction"
                                onClick={confirmDeleteCreditTransaction}
                                style={CiroButtonStyleEnum.DANGER}
                              >
                                Confirm
                              </CiroButton>
                              <CiroButton
                                analyticsField="Cancel Delete Transaction"
                                onClick={cancelDeleteCreditTransaction}
                              >
                                Cancel
                              </CiroButton>
                            </>
                          )}
                        </div>
                      ) : (
                        <CiroButton
                          analyticsField="Delete Transaction"
                            onClick={() => {
                              const id = creditTransaction.id?.value
                              if (id) {
                                startDeletingCreditTransaction(id)
                              }
                            }
                          }
                        >
                          Delete
                        </CiroButton>
                      )}
                    </CiroTableCell>
                  </CiroTableRow>
                );
              })}
            </tbody>
          </CiroTable>
        </div>
      )}
    </div>
  );
};

export default AdminViewCreditPlanDetails;
