import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import * as yup from "yup";
import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { IEnrichmentStepTechniqueCardProps } from ".";
import { useFragment } from "../../../../__generated__";
import {
  EnrichmentStep,
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragment,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import classNames from "classnames";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import { FormProvider, useWatch } from "react-hook-form";
import { useEffect, useMemo } from "react";
import CiroDropDown from "../../../shared/CiroDropdown";
import CiroTooltipContainer from "../../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const FIND_PHONE_CONTACT_ID_METHOD = "contact_id_method";
const FIND_PHONE_FULL_NAME_METHOD = "full_name_method";
const FIND_PHONE_EMAIL_OR_LINKEDIN_METHOD = "email_or_linkedin_method";

export const EnrichmentStepTechniqueFindPhoneCardSchema = yup
  .object({
    runMethod: yup.string().required("Please select the enrichment method"),
    companyName: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    contactId: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    email: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    firstName: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    lastName: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    linkedinUrl: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
    ignoreNumber: yup.object({
      input: yup.string().nullable(),
      sourceEnrichmentStepId: yup.number().nullable(),
    }),
  })
  .required()
  .test(
    ({
      runMethod,
      companyName,
      contactId,
      email,
      firstName,
      lastName,
      linkedinUrl,
    }) => {
      if (runMethod == null) {
        return true;
      }

      if (
        runMethod === FIND_PHONE_CONTACT_ID_METHOD &&
        !contactId.sourceEnrichmentStepId
      ) {
        return new yup.ValidationError(
          "Contact ID is required",
          "Contact ID is required",
          "generalMethodRequirements",
        );
      }
      if (
        runMethod === FIND_PHONE_EMAIL_OR_LINKEDIN_METHOD &&
        !email.sourceEnrichmentStepId &&
        !linkedinUrl.sourceEnrichmentStepId
      ) {
        return new yup.ValidationError(
          "Email or Linkedin URL is required",
          "Email or Linkedin URL is required",
          "generalMethodRequirements",
        );
      }
      if (
        runMethod === FIND_PHONE_FULL_NAME_METHOD &&
        !firstName.sourceEnrichmentStepId &&
        !lastName.sourceEnrichmentStepId &&
        !companyName.sourceEnrichmentStepId
      ) {
        return new yup.ValidationError(
          "First Name, Last Name and Company Name are required",
          "First Name, Last Name and Company Name are required",
          "generalMethodRequirements",
        );
      }
      return true;
    },
  );

export const getFindPhoneDefaultValues = (
  enrichmentStep: EnrichmentStep,
  enrichmentStepWithFilters?: EnrichmentStepCardFiltersList_EnrichmentStepFragment,
) => {
  const companyNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "companyName",
  );
  const contactIdInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "contactId",
  );
  const emailInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "email",
  );
  const firstNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "firstName",
  );
  const lastNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "lastName",
  );
  const linkedinUrlInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "linkedinUrl",
  );
  const runMethod = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "runMethod",
  );
  const ignoreNumber = enrichmentStep?.parentEnrichmentStepInputs?.find(
    (stepInput) => stepInput.key === "ignoreNumber",
  );

  const filterEnrichmentSteps = parseEnrichmentStepsForList(
    enrichmentStepWithFilters,
  );

  return (
    {
      name: enrichmentStep?.name,
      enrichment_technique: EnrichmentTechniqueEnum.FindPhone,
      runMethod: JSON.parse(runMethod?.input || "null"),
      companyName: {
        input: JSON.parse(companyNameInput?.input || "null") || "",
        sourceEnrichmentStepId:
          companyNameInput?.sourceEnrichmentStep?.id || null,
      },
      contactId: {
        input: JSON.parse(contactIdInput?.input || "null") || "",
        sourceEnrichmentStepId:
          contactIdInput?.sourceEnrichmentStep?.id || null,
      },
      email: {
        input: JSON.parse(emailInput?.input || "null") || "",
        sourceEnrichmentStepId: emailInput?.sourceEnrichmentStep?.id || null,
      },
      firstName: {
        input: JSON.parse(firstNameInput?.input || "null") || "",
        sourceEnrichmentStepId:
          firstNameInput?.sourceEnrichmentStep?.id || null,
      },
      lastName: {
        input: JSON.parse(lastNameInput?.input || "null") || "",
        sourceEnrichmentStepId: lastNameInput?.sourceEnrichmentStep?.id || null,
      },
      linkedinUrl: {
        input: JSON.parse(linkedinUrlInput?.input || "null") || "",
        sourceEnrichmentStepId:
          linkedinUrlInput?.sourceEnrichmentStep?.id || null,
      },
      ignoreNumber: {
        input: JSON.parse(ignoreNumber?.input || "null") || "",
        sourceEnrichmentStepId: ignoreNumber?.sourceEnrichmentStep?.id || null,
      },
      filterEnrichmentSteps: filterEnrichmentSteps,
    } || []
  );
};

const EnrichmentStepTechniqueFindPhoneCard = ({
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
  loading,
  error,
}: IEnrichmentStepTechniqueCardProps) => {
  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );
  const defaultValues = useMemo(() => {
    return getFindPhoneDefaultValues(
      enrichmentStep as EnrichmentStep,
      enrichmentStepWithFilters as EnrichmentStepCardFiltersList_EnrichmentStepFragment,
    );
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formatAndSubmitRequest = (data: any) => {
    let parentEnrichmentStepInputs = [
      {
        key: "runMethod",
        input: JSON.stringify(data.runMethod),
        required: true,
        sourceEnrichmentStepId: null,
      },
    ] as {
      key: string;
      input?: string | null | undefined;
      required: boolean;
      sourceEnrichmentStepId?: number | null | undefined;
    }[];

    if (data.runMethod === FIND_PHONE_CONTACT_ID_METHOD) {
      parentEnrichmentStepInputs.push({
        key: "contactId",
        input: JSON.stringify(data.contactId.input),
        required: true,
        sourceEnrichmentStepId: data.contactId.sourceEnrichmentStepId,
      });
    }
    if (data.runMethod === FIND_PHONE_FULL_NAME_METHOD) {
      parentEnrichmentStepInputs.push({
        key: "companyName",
        input: JSON.stringify(data.companyName.input),
        required: true,
        sourceEnrichmentStepId: data.companyName.sourceEnrichmentStepId,
      });
      parentEnrichmentStepInputs.push({
        key: "firstName",
        input: JSON.stringify(data.firstName.input),
        required: true,
        sourceEnrichmentStepId: data.firstName.sourceEnrichmentStepId,
      });
      parentEnrichmentStepInputs.push({
        key: "lastName",
        input: JSON.stringify(data.lastName.input),
        required: true,
        sourceEnrichmentStepId: data.lastName.sourceEnrichmentStepId,
      });
    }
    if (data.runMethod === FIND_PHONE_EMAIL_OR_LINKEDIN_METHOD) {
      parentEnrichmentStepInputs.push({
        key: "email",
        input: JSON.stringify(data.email.input),
        required: true,
        sourceEnrichmentStepId: data.email.sourceEnrichmentStepId,
      });
      parentEnrichmentStepInputs.push({
        key: "linkedinUrl",
        input: JSON.stringify(data.linkedinUrl.input),
        required: true,
        sourceEnrichmentStepId: data.linkedinUrl.sourceEnrichmentStepId,
      });
    }

    parentEnrichmentStepInputs.push({
      key: "ignoreNumber",
      input: JSON.stringify(data.ignoreNumber.input),
      required: false,
      sourceEnrichmentStepId: data.ignoreNumber.sourceEnrichmentStepId,
    });

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique,
          selected_input: null,
          parentEnrichmentStepInputs,
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniqueFindPhoneCardSchema),
    defaultValues,
  });

  const {
    control,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    reset,
    clearErrors,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
    return () => {
      reset();
    };
  }, [defaultValues, reset]);

  const [
    companyName,
    contactId,
    email,
    filterEnrichmentSteps,
    firstName,
    lastName,
    linkedinUrl,
    runMethod,
    ignoreNumber,
  ] = useWatch({
    control,
    name: [
      "companyName",
      "contactId",
      "email",
      "filterEnrichmentSteps",
      "firstName",
      "lastName",
      "linkedinUrl",
      "runMethod",
      "ignoreNumber",
    ],
  });

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <CiroDropDown
        label="Enrichment Method"
        error={errors?.runMethod?.message as string}
        labelClassName={classNames("ciro-v1-text-gray-500")}
        creatable
        isMulti={false}
        options={[
          {
            value: FIND_PHONE_CONTACT_ID_METHOD,
            label: "Use Ciro Contact",
          },
          {
            value: FIND_PHONE_EMAIL_OR_LINKEDIN_METHOD,
            label: "Search by Email/Linkedin",
          },
          {
            value: FIND_PHONE_FULL_NAME_METHOD,
            label: "Search by Full Name and Company",
          },
        ]}
        value={runMethod || ""}
        isClearable
        onChange={(v) => {
          clearErrors();
          setValue("runMethod", v as any);
        }}
      />
      {runMethod === FIND_PHONE_CONTACT_ID_METHOD && (
        <div>
          <div className={classNames("ciro-v1-pt-4")}>Contact ID</div>
          <EnrichmentStepSelector
            inputError={errors.contactId?.message}
            stepVariable={{
              stepId: contactId.sourceEnrichmentStepId || null,
              stepInput: contactId.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "contactId.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("contactId.input", newVariable?.stepInput || "");
            }}
          />
        </div>
      )}
      {runMethod === FIND_PHONE_EMAIL_OR_LINKEDIN_METHOD && (
        <div>
          <div className={classNames("ciro-v1-pt-4")}>Linkedin URL</div>
          <EnrichmentStepSelector
            inputError={errors.linkedinUrl?.message}
            stepVariable={{
              stepId: linkedinUrl.sourceEnrichmentStepId || null,
              stepInput: linkedinUrl.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "linkedinUrl.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("linkedinUrl.input", newVariable?.stepInput || "");
            }}
          />
          <div
            className={classNames(
              "ciro-v1-pt-4",
              "ciro-v1-italic",
              "ciro-v1-text-gray-400",
            )}
          >
            And/Or
          </div>
          <div className={classNames("ciro-v1-pt-4")}>Email</div>
          <EnrichmentStepSelector
            inputError={errors.email?.message}
            stepVariable={{
              stepId: email.sourceEnrichmentStepId || null,
              stepInput: email.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "email.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("email.input", newVariable?.stepInput || "");
            }}
          />
        </div>
      )}
      {runMethod === FIND_PHONE_FULL_NAME_METHOD && (
        <div>
          <div className={classNames("ciro-v1-pt-4")}>First Name</div>
          <EnrichmentStepSelector
            inputError={errors.firstName?.message}
            stepVariable={{
              stepId: firstName.sourceEnrichmentStepId || null,
              stepInput: firstName.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "firstName.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("firstName.input", newVariable?.stepInput || "");
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>Last Name</div>
          <EnrichmentStepSelector
            inputError={errors.lastName?.message}
            stepVariable={{
              stepId: lastName.sourceEnrichmentStepId || null,
              stepInput: lastName.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "lastName.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("lastName.input", newVariable?.stepInput || "");
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>Company Name</div>
          <EnrichmentStepSelector
            inputError={errors.companyName?.message}
            stepVariable={{
              stepId: companyName.sourceEnrichmentStepId || null,
              stepInput: companyName.input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "companyName.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue("companyName.input", newVariable?.stepInput || "");
            }}
          />
        </div>
      )}
      <div className={classNames("ciro-v1-flex", "ciro-v1-pt-4")}>
        <div>Ignore Number</div>
        <div className={classNames("ciro-v1-ml-1", "ciro-v1-text-gray-400")}>
          <CiroTooltipContainer
            tooltip={
              <div>
                Provide a number to ignore from the results. This number will
                not be returned in the output if it matches a number we find.
              </div>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </CiroTooltipContainer>
        </div>
      </div>
      <EnrichmentStepSelector
        inputError={errors.ignoreNumber?.message}
        stepVariable={{
          stepId: ignoreNumber.sourceEnrichmentStepId || null,
          stepInput: ignoreNumber.input,
        }}
        setStepVariable={(newVariable) => {
          setValue("ignoreNumber.sourceEnrichmentStepId", newVariable.stepId);
          setValue("ignoreNumber.input", newVariable.stepInput);
        }}
      />
      {Boolean((errors as any)?.generalMethodRequirements?.message) && (
        <div
          className={classNames(
            "ciro-v1-text-xs",
            "ciro-v1-text-rose-500",
            "ciro-v1-mt-4",
          )}
        >
          {(errors as any)?.generalMethodRequirements?.message}
        </div>
      )}
      <div className={classNames("ciro-v1-mt-12")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniqueFindPhoneCard;
