import { ForwardIcon } from "@heroicons/react/24/outline";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import CiroModal from "../shared/CiroModal";
import XIcon from "../../assets/img/icons/XIcon";
import CiroDropDown from "../shared/CiroDropdown";
import SkeletonLoading from "../shared/SkeletonLoading";
import { gql, useMutation, useQuery } from "@apollo/client";
import { format, subDays, subMonths, subWeeks } from "date-fns";
import {
  MobileNumbersRunButton_ContactsToEnrichQuery,
  MobileNumbersRunButton_ContactsToEnrichQueryVariables,
  MobileNumbersRunButton_CreatePhoneNumbersRequestTransactionMutation,
  MobileNumbersRunButton_CreatePhoneNumbersRequestTransactionMutationVariables,
} from "../../__generated__/graphql";
import { MobileNumbers_Query } from "../../routes/enrichments/MobileNumbers";
import CiroCoinPill from "../shared/CiroCoinPill";

const MobileNumbersRunButton_ContactsToEnrich = gql`
  query MobileNumbersRunButton_ContactsToEnrich($after: Date) {
    newPhoneNumberRequests(after: $after) {
      phoneNumberRequests {
        org_contact_id
        org_phone_call_id
      }
      creditsNeeded
    }
    userAccount {
      org {
        credit_plan {
          balance
        }
      }
    }
  }
`;

const MobileNumbersRunButton_CreatePhoneNumbersRequestTransaction = gql`
  mutation MobileNumbersRunButton_CreatePhoneNumbersRequestTransaction(
    $phoneNumberRequests: [PhoneNumberRequestInput!]!
  ) {
    createNewPhoneNumbersRequestTransaction(
      phoneNumberRequests: $phoneNumberRequests
    ) {
      id
    }
  }
`;

const MobileNumbersRunButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLookBackPeriod, setSelectedLookBackPeriod] = useState<
    string | null
  >(null);
  const { data: contactsToEnrichData, loading: contactsToEnrichLoading } =
    useQuery<
      MobileNumbersRunButton_ContactsToEnrichQuery,
      MobileNumbersRunButton_ContactsToEnrichQueryVariables
    >(MobileNumbersRunButton_ContactsToEnrich, {
      skip: !isModalOpen,
      fetchPolicy: "network-only",
      variables: {
        after: selectedLookBackPeriod,
      },
    });

  const [
    createPhoneNumbersRequestTransaction,
    {
      loading: createPhoneNumbersRequestTransactionLoading,
      error: createPhoneNumbersRequestTransactionError,
    },
  ] = useMutation<
    MobileNumbersRunButton_CreatePhoneNumbersRequestTransactionMutation,
    MobileNumbersRunButton_CreatePhoneNumbersRequestTransactionMutationVariables
  >(MobileNumbersRunButton_CreatePhoneNumbersRequestTransaction);

  const dropdownOptions = useMemo(() => {
    if (isModalOpen) {
      return [
        {
          label: "1 day",
          value: format(subDays(new Date(), 1), "yyyy-MM-dd"),
        },
        {
          label: "1 week",
          value: format(subWeeks(new Date(), 1), "yyyy-MM-dd"),
        },
        {
          label: "1 month",
          value: format(subMonths(new Date(), 1), "yyyy-MM-dd"),
        },
        {
          label: "Entire history",
          value: null,
        },
      ];
    } else {
      return [];
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isModalOpen) {
      setSelectedLookBackPeriod(null);
    }
  }, [isModalOpen]);

  const contactsToEnrich =
    contactsToEnrichData?.newPhoneNumberRequests?.phoneNumberRequests || [];
  const contactCount = contactsToEnrich.length;
  const creditsNeeded =
    contactsToEnrichData?.newPhoneNumberRequests?.creditsNeeded || 0;
  const creditsAvailable =
    contactsToEnrichData?.userAccount?.org?.credit_plan?.balance || 0;

  let errorMsg = null;
  if (createPhoneNumbersRequestTransactionError) {
    errorMsg = createPhoneNumbersRequestTransactionError.message;
  } else if (creditsAvailable < creditsNeeded) {
    errorMsg = "You do not have enough credits to run this enrichment";
  }

  return (
    <>
      <CiroButton
        analyticsField="Open Run Mobile Numbers modal"
        style={CiroButtonStyleEnum.LOUD}
        onClick={() => setIsModalOpen(true)}
      >
        <ForwardIcon
          className={classNames("ciro-v1-w-4", "ciro-v1-h-4", "ciro-v1-mr-2")}
        />
        Run Now
      </CiroButton>
      <CiroModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classNames("ciro-v1-flex", "ciro-v1-justify-between")}>
          <div
            className={classNames(
              "ciro-v1-text-lg",
              "ciro-v1-font-semibold",
              "ciro-v1-pb-2",
            )}
          >
            Run Now
          </div>
          <CiroButton
            style={CiroButtonStyleEnum.UNSTYLED}
            analyticsField="Close Run Mobile Numbers modal"
            onClick={() => setIsModalOpen(false)}
          >
            <XIcon />
          </CiroButton>
        </div>
        <div className={classNames("ciro-v1-min-h-[5rem]")}>
          <CiroDropDown
            isMulti={false}
            label="Choose the look-back period for the enrichment"
            options={dropdownOptions}
            value={selectedLookBackPeriod}
            onChange={(value) => setSelectedLookBackPeriod(value)}
            maxMenuHeight={100}
          />
          <div className={classNames("ciro-v1-py-4")}>
            {contactsToEnrichLoading && (
              <SkeletonLoading
                numSkeletons={1}
                skeletonHeight={"20px"}
                skeletonZIndex={0}
              />
            )}
            {!contactsToEnrichLoading && (
              <div
                className={classNames(
                  "ciro-v1-flex",
                  "ciro-v1-justify-between",
                  "ciro-v1-items-center",
                  "ciro-v1-bg-neutral-50",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-p-4",
                )}
              >
                <div
                  className={classNames(
                    "ciro-v1-text-xs",
                    "ciro-v1-text-gray-500",
                    "ciro-v1-mr-1"
                  )}
                >
                  {contactCount > 0 &&
                    `Mobile numbers for ${contactCount} contacts will be searched for`}
                  {contactCount === 0 &&
                    "Could not find any contacts requiring new mobile numbers"}
                </div>
                <CiroCoinPill additionalText="Max" amount={creditsNeeded} />
              </div>
            )}
          </div>
          <CiroButton
            analyticsField="Run Mobile Numbers"
            style={CiroButtonStyleEnum.LOUD}
            onClick={() => {
              createPhoneNumbersRequestTransaction({
                variables: {
                  phoneNumberRequests: contactsToEnrich.map((contact) => ({
                    org_contact_id: contact.org_contact_id,
                    org_phone_call_id: contact.org_phone_call_id,
                  })),
                },
                onCompleted(data) {
                  if (data.createNewPhoneNumbersRequestTransaction.id) {
                    setIsModalOpen(false);
                  }
                },
                refetchQueries: [MobileNumbers_Query],
              });
            }}
            customClassName={classNames("ciro-v1-w-full")}
            disabled={
              contactCount === 0 ||
              contactsToEnrichLoading ||
              createPhoneNumbersRequestTransactionLoading ||
              creditsAvailable < creditsNeeded
            }
          >
            Run
          </CiroButton>
          {errorMsg && (
            <div
              className={classNames(
                "ciro-v1-text-red-900",
                "ciro-v1-bg-red-100",
                "ciro-v1-mt-4",
                "ciro-v1-p-2",
                "ciro-v1-rounded-lg",
                "ciro-v1-text-xs",
              )}
            >
              {errorMsg}
            </div>
          )}
        </div>
      </CiroModal>
    </>
  );
};

export default MobileNumbersRunButton;
