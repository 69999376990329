import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import classNames from "classnames";
import {
  DownloadEnrichmentFlowButton_EnrichmentFlowFragmentDoc,
  EnrichmentFlowTableContainer_EnrichmentFlowFragment,
  EnrichmentFlowTableContainer_EnrichmentFlowRowsQuery,
  EnrichmentFlowTableContainer_EnrichmentFlowRowsQueryVariables,
  Maybe,
  EnrichmentTechniqueEnum,
  EnrichmentFlowTableContainer_SaveColumnOrderMutation,
  EnrichmentFlowTableContainer_SaveColumnOrderMutationVariables,
  EnrichmentFlowAddMoreButton_EnrichmentFlowFragmentDoc,
  EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatusQuery,
  EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatusQueryVariables,
  JobStateEnum,
  EnrichmentFlowTableContainer_EnrichmentFlowRunningJobQuery,
  EnrichmentFlowTableContainer_EnrichmentFlowRunningJobQueryVariables,
} from "../../../__generated__/graphql";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { IEnrichmentExecutionValue } from "./EnrichmentExecutionCell";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import EnrichmentStepCard from "../EnrichmentStepCard/EnrichmentStepCard";
import { useFragment } from "../../../__generated__";
import DownloadEnrichmentFlowButton, {
  DownloadEnrichmentFlowButton_EnrichmentFlow,
} from "../DownloadEnrichmentFlowButton";
import EnrichmentFlowContext from "../../../contexts/EnrichmentFlowContext";
import debounce from "../../../utils/debounce";
import RunEnrichmentFlowButton, {
  ONLY_INITIALIZE_TOOLTIP,
  REMOVE_FILTERS_TOOLTIP,
  RUNNING_JOB_TOOLTIP,
  SELECTED_ENRICHMENT_TOOLTIP,
} from "../RunEnrichmentFlowButton";
import { enrichmentFilters } from "../EnrichmentStepCard/EnrichmentStepCardFilters/EnrichmentStepFilterDropdown";
import ExecutionCell_PaginationRow from "./EnrichmentExecutionCell/ExecutionCell_PaginationRow";
import EnrichmentFlowNewStepDropdown from "../EnrichmentFlowNewStepDropdown";
import EnrichmentFlowAddMoreButton, {
  EnrichmentFlowAddMoreButton_EnrichmentFlow,
} from "../EnrichmentFlowAddMore/EnrichmentFlowAddMoreButton";
import useEnrichmentFlowGridInputs from "../../../reactHooks/enrichmentFlow/useEnrichmentFlowGridInputs";
import { IRowNode } from "ag-grid-community";
import EnrichmentFlowClearSelectionButton from "./EnrichmentFlowClearSelectionButton";
import EnrichmentFlowDeleteSelectedRowsButton from "../EnrichmentFlowDeleteRows/EnrichmentFlowDeleteSelectedRowsButton";
import EnrichmentExecutionDetailsViewer, {
  IExecutionCellData,
} from "../EnrichmentExecutionDetailsViewer/EnrichmentExecutionDetailsViewer";
import toast from "react-hot-toast";
import LoadingBar, { loadingBarHeight } from "../../shared/LoadingBar";
import { useLocation } from "react-router-dom";
import CiroAlert from "../../shared/CiroAlert";
import EnrichmentFlowTableFilterWrapper from "./EnrichmentFlowTableFilters/EnrichmentFlowTableFilterWrapper";
import {
  getFiltersAppliedCount,
  setFiltersToArray,
} from "../../../reactHooks/filters/enrichmentFlow/useEnrichmentFlowFilters";

export const EnrichmentFlowTableContainer_EnrichmentFlow = gql`
  fragment EnrichmentFlowTableContainer_EnrichmentFlow on EnrichmentFlow {
    id
    enrichment_step_order
    enrichmentSteps {
      id
      name
      selected_input
      enrichment_technique
      enrichmentExecutionCount
    }
    rowCount
    ...DownloadEnrichmentFlowButton_EnrichmentFlow
    ...EnrichmentFlowAddMoreButton_EnrichmentFlow
  }
  ${DownloadEnrichmentFlowButton_EnrichmentFlow}
  ${EnrichmentFlowAddMoreButton_EnrichmentFlow}
`;

const EnrichmentFlowTableContainer_EnrichmentFlowRows = gql`
  query EnrichmentFlowTableContainer_EnrichmentFlowRows(
    $enrichmentFlowRowsInput: EnrichmentFlowRowsInput!
  ) {
    enrichmentFlowRows(input: $enrichmentFlowRowsInput) {
      id
      enrichmentExecutions {
        id
        output
        error
        enrichment_step_id
        enrichment_flow_row_id
      }
    }
  }
`;

const EnrichmentFlowTableContainer_SaveColumnOrder = gql`
  mutation EnrichmentFlowTableContainer_SaveColumnOrder(
    $id: Int!
    $enrichmentFlowInput: EnrichmentFlowInput!
  ) {
    updateEnrichmentFlow(id: $id, enrichmentFlowInput: $enrichmentFlowInput) {
      id
      # Including so that it will update correctly
      enrichment_step_order
    }
  }
`;

const EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatus = gql`
  query EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatus(
    $jobId: String!
  ) {
    runEnrichmentFlowJobStatus(jobId: $jobId) {
      state
      details {
        completedRows {
          stepId
          rowIds
        }
        targetStepIds
        targetRowIds
        numRowsToRun
        rowsRun
      }
    }
  }
`;

const EnrichmentFlowTableContainer_EnrichmentFlowRunningJob = gql`
  query EnrichmentFlowTableContainer_EnrichmentFlowRunningJob($id: Int!) {
    enrichmentFlowRunningJob(id: $id)
  }
`;

interface IEnrichmentFlowTableContainerProps {
  enrichmentFlow?: Maybe<EnrichmentFlowTableContainer_EnrichmentFlowFragment>;
  hintCardVisible?: boolean;
}

export const ENRICHMENT_FLOW_ROW_LIMIT = 100;
export const AG_GRID_ROW_HEIGHT = 40;

const EnrichmentFlowTableContainer = ({
  enrichmentFlow,
  hintCardVisible,
}: IEnrichmentFlowTableContainerProps) => {
  const [offset, setOffset] = useState(0);
  const [runPercentage, setRunPercentage] = useState(0);
  const [showTemplateAlert, setShowTemplateAlert] = useState(false);
  const {
    EnrichmentFlow_refetchEnrichmentFlow,
    loadingRowIds,
    runningJobId,
    selectedRows,
    setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    setGridRef,
    setLoadingRowIds,
    setLoadingStepIds,
    setRunningJobId,
    enrichmentFlowFilters,
  } = useContext(EnrichmentFlowContext);

  const gridRef = useRef<AgGridReact>(null);

  const location = useLocation();

  const [fetchRunJobStatus, { data: runJobStatusData }] = useLazyQuery<
    EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatusQuery,
    EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatusQueryVariables
  >(EnrichmentFlowTableContainer_RunEnrichmentFlowJobStatus);

  const { data: runningJobIdData } = useQuery<
    EnrichmentFlowTableContainer_EnrichmentFlowRunningJobQuery,
    EnrichmentFlowTableContainer_EnrichmentFlowRunningJobQueryVariables
  >(EnrichmentFlowTableContainer_EnrichmentFlowRunningJob, {
    variables: {
      id: enrichmentFlow?.id || -1,
    },
    fetchPolicy: "network-only",
  });

  const {
    data: enrichmentFlowRowsData,
    loading: enrichmentFlowRowsLoading,
    refetch: EnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
  } = useQuery<
    EnrichmentFlowTableContainer_EnrichmentFlowRowsQuery,
    EnrichmentFlowTableContainer_EnrichmentFlowRowsQueryVariables
  >(EnrichmentFlowTableContainer_EnrichmentFlowRows, {
    variables: {
      enrichmentFlowRowsInput: {
        limit: ENRICHMENT_FLOW_ROW_LIMIT,
        offset,
        enrichmentFlowId: enrichmentFlow?.id || -1,
        filters: setFiltersToArray(enrichmentFlowFilters),
      },
    },
    fetchPolicy: "network-only",
  });

  const [saveColumnOrder] = useMutation<
    EnrichmentFlowTableContainer_SaveColumnOrderMutation,
    EnrichmentFlowTableContainer_SaveColumnOrderMutationVariables
  >(EnrichmentFlowTableContainer_SaveColumnOrder);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const truncatedRows = queryParams.get("truncated");
    const template = queryParams.get("template");

    if (truncatedRows) {
      toast(
        "Data has been truncated to " +
          truncatedRows +
          " rows.  If you need to enrich more data, please upgrade your account.",
        {
          icon: "🚀",
          style: {
            textAlign: "left",
          },
          duration: 6000,
        },
      );
    }
    if (template) {
      setShowTemplateAlert(true);
    }
  }, [location]);

  useEffect(() => {
    if (runningJobIdData?.enrichmentFlowRunningJob) {
      setRunningJobId(runningJobIdData.enrichmentFlowRunningJob.toString());
      fetchRunJobStatus({
        variables: { jobId: runningJobIdData.enrichmentFlowRunningJob },
        fetchPolicy: "no-cache",
      }).then(({ data: runJobData }) => {
        setLoadingRowIds(
          new Set(
            runJobData?.runEnrichmentFlowJobStatus?.details?.targetRowIds,
          ),
        );
        setLoadingStepIds(
          new Set(
            runJobData?.runEnrichmentFlowJobStatus?.details?.targetStepIds,
          ),
        );
      });
    }
  }, [
    runningJobIdData,
    setRunningJobId,
    fetchRunJobStatus,
    setLoadingRowIds,
    setLoadingStepIds,
  ]);

  useEffect(() => {
    if (runningJobId) {
      const pollRunJobStatus = setInterval(() => {
        fetchRunJobStatus({
          variables: { jobId: runningJobId },
          fetchPolicy: "no-cache",
        }).then(({ data: runJobStatusData }) => {
          const rowsRun =
            runJobStatusData?.runEnrichmentFlowJobStatus?.details?.rowsRun;
          const numRowsToRun =
            runJobStatusData?.runEnrichmentFlowJobStatus?.details?.numRowsToRun;
          if (rowsRun && numRowsToRun) {
            setRunPercentage(rowsRun / numRowsToRun);
          } else {
            setRunPercentage(0);
          }
          const completedRows =
            runJobStatusData?.runEnrichmentFlowJobStatus?.details
              ?.completedRows;
          if (completedRows) {
            EnrichmentFlowTableContainer_refetchEnrichmentFlowRows();
            completedRows.forEach((tuple) => {
              if (
                tuple?.rowIds?.length ===
                runJobStatusData?.runEnrichmentFlowJobStatus?.details
                  ?.targetRowIds?.length
              ) {
                const copyLoadingStepIds = new Set(
                  runJobStatusData.runEnrichmentFlowJobStatus?.details
                    ?.targetStepIds!,
                );
                copyLoadingStepIds.delete(tuple?.stepId!);
                setLoadingStepIds(copyLoadingStepIds);
              }
            });
            const rowIdArrays = Object.values(completedRows).map(
              (tuple) => tuple?.rowIds,
            );
            if (rowIdArrays.length !== 0) {
              const sets = rowIdArrays.map((array) => new Set(array));

              const commonValues = sets.reduce((common, set) => {
                return new Set([...common].filter((x) => set.has(x)));
              });
              const copyLoadingRowIds = new Set(
                runJobStatusData.runEnrichmentFlowJobStatus?.details
                  ?.targetRowIds!,
              );
              commonValues?.forEach((rowId: any) => {
                copyLoadingRowIds.delete(rowId);
              });
              setLoadingRowIds(copyLoadingRowIds);
            }
          }

          if (
            runJobStatusData?.runEnrichmentFlowJobStatus?.state ===
              JobStateEnum.Completed ||
            runJobStatusData?.runEnrichmentFlowJobStatus?.state ===
              JobStateEnum.Failed
          ) {
            clearInterval(pollRunJobStatus);
            setLoadingRowIds(null);
            setLoadingStepIds(new Set());
            EnrichmentFlowTableContainer_refetchEnrichmentFlowRows();
            EnrichmentFlow_refetchEnrichmentFlow();
            setRunningJobId(null);
            setRunPercentage(0);
            if (
              runJobStatusData?.runEnrichmentFlowJobStatus?.state ===
              JobStateEnum.Completed
            ) {
              toast.success("Run completed");
            }
            if (
              runJobStatusData?.runEnrichmentFlowJobStatus?.state ===
              JobStateEnum.Failed
            ) {
              toast.error("Run failed");
            }
          }
        });
      }, 1000);
      return () => clearInterval(pollRunJobStatus);
    }
  }, [
    runJobStatusData?.runEnrichmentFlowJobStatus?.state,
    runningJobId,
    EnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    EnrichmentFlow_refetchEnrichmentFlow,
    fetchRunJobStatus,
    loadingRowIds,
    setLoadingRowIds,
    setLoadingStepIds,
    setRunningJobId,
  ]);

  const processBottomRowData = useCallback(() => {
    const data = enrichmentFlow!.enrichmentSteps.reduce(
      (acc, step) => {
        acc[String(step.id)] = {
          output: step.enrichmentExecutionCount || 0,
        };
        return acc;
      },
      {} as { [key: string]: IEnrichmentExecutionValue },
    );

    return [data, { isPagination: true }];
  }, [enrichmentFlow]);

  useEffect(() => {
    setGridRef(gridRef);
    setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows(
      () => EnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    );
  }, [
    setGridRef,
    gridRef,
    EnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
    setEnrichmentFlowTableContainer_refetchEnrichmentFlowRows,
  ]);

  useEffect(() => {
    const selected: IRowNode<any>[] = [];
    const deselected: IRowNode<any>[] = [];

    gridRef?.current?.api?.forEachNode((node: IRowNode<any>) => {
      if (!node) return;
      if (
        selectedRows.tableIndexStart === null ||
        selectedRows.tableIndexEnd === null
      )
        return;
      if (
        node.rowIndex! + offset >= selectedRows.tableIndexStart! &&
        node.rowIndex! + offset <= selectedRows.tableIndexEnd!
      ) {
        selected.push(node);
      } else {
        deselected.push(node);
      }
    });
    gridRef?.current?.api?.setNodesSelected({
      nodes: selected,
      newValue: true,
    });
    gridRef?.current?.api?.setNodesSelected({
      nodes: deselected,
      newValue: false,
    });
  });

  const onlyInitializeSteps = useCallback(
    () =>
      enrichmentFlow?.enrichmentSteps.every(
        (step) =>
          step.enrichment_technique === "initialize" ||
          step.enrichment_technique === "initialize_company",
      )
        ? true
        : false,
    [enrichmentFlow],
  );

  const [executionCellData, setExecutionCellData] =
    useState<IExecutionCellData | null>(null);
  const [selectedEnrichmentStepId, setSelectedEnrichmentStepId] = useState<
    null | number
  >(null);
  const [selectedEnrichmentTechnique, setSelectedEnrichmentTechnique] =
    useState<EnrichmentTechniqueEnum | null>(null);

  const downloadFlowEnrichmentFlow = useFragment(
    DownloadEnrichmentFlowButton_EnrichmentFlowFragmentDoc,
    enrichmentFlow,
  );
  const enrichmentFlowAddMoreFlow = useFragment(
    EnrichmentFlowAddMoreButton_EnrichmentFlowFragmentDoc,
    enrichmentFlow,
  );

  const [enrichmentStepOrder, setEnrichmentStepOrder] = useState(
    enrichmentFlow?.enrichment_step_order || [],
  );

  const [showFiltersAsColumns, setShowFiltersAsColumns] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceColumnMoved = useCallback(
    debounce(() => {
      const columnDefs = gridRef?.current?.api.getColumnDefs() as any;
      const columnIdOrder = columnDefs
        ?.map(
          (columnDef: any) => columnDef.headerComponentParams.enrichmentStepId,
        )
        .filter(Boolean);

      setEnrichmentStepOrder(columnIdOrder); // Prevents flash of previous enrichment step order
      saveColumnOrder({
        variables: {
          id: enrichmentFlow?.id || -1,
          enrichmentFlowInput: {
            enrichmentStepOrder: columnIdOrder,
          },
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    }, 1000),
    [enrichmentFlow, saveColumnOrder, gridRef],
  );

  const { rowData, rowIds, columnDefs } = useEnrichmentFlowGridInputs({
    enrichmentFilters,
    enrichmentFlow,
    enrichmentFlowRowsData,
    enrichmentFlowRowsLoading,
    offset,
    enrichmentStepOrder,
    setExecutionCellData,
    setSelectedEnrichmentStepId,
    setSelectedEnrichmentTechnique,
    showFiltersAsColumns,
    setShowFiltersAsColumns,
  });

  const [filterIsOpen, setFilterIsOpen] = useState(false);

  if (enrichmentFlow == null) {
    return null;
  }

  const rowsSelected =
    selectedRows.tableIndexStart !== null &&
    selectedRows.tableIndexEnd !== null;

  let runEnrichmentFlowTooltip = null;
  const runEnrichmentFlowDisabled =
    getFiltersAppliedCount(enrichmentFlowFilters) > 0 ||
    Boolean(selectedEnrichmentTechnique) ||
    runningJobId != null ||
    onlyInitializeSteps();

  if (getFiltersAppliedCount(enrichmentFlowFilters) > 0) {
    runEnrichmentFlowTooltip = REMOVE_FILTERS_TOOLTIP;
  }
  if (Boolean(selectedEnrichmentTechnique)) {
    runEnrichmentFlowTooltip = SELECTED_ENRICHMENT_TOOLTIP;
  }
  if (runningJobId != null) {
    runEnrichmentFlowTooltip = RUNNING_JOB_TOOLTIP;
  }
  if (onlyInitializeSteps()) {
    runEnrichmentFlowTooltip = ONLY_INITIALIZE_TOOLTIP;
  }

  return (
    <>
      {showTemplateAlert && (
        <CiroAlert
          message={
            "You have created an enrichment from a template. Click “edit” in the column header menu to explore how each column works, or just click “run” to run the table and get some data!"
          }
          level="info"
        />
      )}
      <div className={classNames("ciro-v1-w-full")}>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-items-center",
            "ciro-v1-justify-between",
            "ciro-v1-pt-4",
          )}
        >
          <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
            <EnrichmentFlowTableFilterWrapper
              setFilterIsOpen={setFilterIsOpen}
              filterIsOpen={filterIsOpen}
            />
          </div>
          <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
            {rowsSelected && (
              <div className={classNames("ciro-v1-pr-4")}>
                <EnrichmentFlowClearSelectionButton />
              </div>
            )}
            {rowsSelected && (
              <div className={classNames("ciro-v1-pr-4")}>
                <EnrichmentFlowDeleteSelectedRowsButton />
              </div>
            )}
            {!rowsSelected && (
              <div className={classNames("ciro-v1-px-4")}>
                <EnrichmentFlowAddMoreButton
                  enrichmentFlow={enrichmentFlowAddMoreFlow}
                />
              </div>
            )}
            <div>
              <DownloadEnrichmentFlowButton
                enrichmentFlow={downloadFlowEnrichmentFlow}
                includeFilterColumns={showFiltersAsColumns}
              />
            </div>
            <div className={classNames("ciro-v1-px-4")}>
              <EnrichmentFlowNewStepDropdown
                setSelectedEnrichmentStepId={setSelectedEnrichmentStepId}
                setSelectedEnrichmentTechnique={setSelectedEnrichmentTechnique}
                closeExecutionCellData={() => setExecutionCellData(null)}
              />
            </div>
            <div>
              <RunEnrichmentFlowButton
                cascade={true}
                offset={offset}
                totalCount={enrichmentFlow.rowCount || 0}
                tooltip={runEnrichmentFlowTooltip}
                disabled={runEnrichmentFlowDisabled}
              />
            </div>
          </div>
        </div>
        {runningJobId && (
          <div className={classNames("ciro-v1-pt-4")}>
            <LoadingBar
              customFillPercentage={runPercentage || 0}
              showPercentage={true}
              height={loadingBarHeight.LARGE}
            />
          </div>
        )}
        <div
          className={classNames(
            "ag-theme-quartz",
            "ciro-v1-w-full",
            { "ciro-v1-h-screen-minus-16": !hintCardVisible },
            { "ciro-v1-h-screen-minus-24": hintCardVisible },
            "ciro-v1-pt-2",
            "ciro-v1-flex",
          )}
        >
          <div className={classNames("ciro-v1-w-full")}>
            <AgGridReact
              rowHeight={AG_GRID_ROW_HEIGHT}
              rowData={rowData}
              columnDefs={columnDefs as any}
              pinnedBottomRowData={processBottomRowData()}
              isFullWidthRow={(params) => {
                return params.rowNode.data?.isPagination;
              }}
              fullWidthCellRenderer={() => {
                return (
                  // eslint-disable-next-line react/jsx-pascal-case
                  <ExecutionCell_PaginationRow
                    offset={offset}
                    perPage={ENRICHMENT_FLOW_ROW_LIMIT}
                    totalCount={enrichmentFlow.rowCount || 0}
                    paginationLimit={9999999}
                    setOffset={setOffset}
                  />
                );
              }}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              onColumnMoved={debounceColumnMoved}
              ref={gridRef}
              rowSelection="multiple"
              suppressScrollOnNewData={true}
            />
          </div>
          <EnrichmentExecutionDetailsViewer
            hidden={!executionCellData}
            executionCellData={executionCellData}
            onClose={() => {
              setExecutionCellData(null);
            }}
          />
          <EnrichmentStepCard
            enrichmentStepId={selectedEnrichmentStepId}
            rowIds={rowIds}
            offset={offset}
            totalCount={enrichmentFlow.rowCount || 0}
            setSelectedEnrichmentStepId={setSelectedEnrichmentStepId}
            onClose={() => {
              setSelectedEnrichmentStepId(null);
              setSelectedEnrichmentTechnique(null);
            }}
            selectedEnrichmentTechnique={selectedEnrichmentTechnique}
          />
        </div>
      </div>
    </>
  );
};

export default EnrichmentFlowTableContainer;
