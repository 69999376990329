import classNames from "classnames";
import React, { KeyboardEventHandler } from "react";

import CreatableSelect from "react-select/creatable";
import { dropdownStylesControl, dropdownStylesOption } from "./CiroDropdown";

interface ICiroCreateableTextInput {
  containerWidthClass?: string;
  label?: string;
  placeholder?: string;
  value: string[];
  setValue: (value: string[]) => void;
}

const CiroCreateableTextInput = ({
  containerWidthClass,
  label,
  placeholder,
  value,
  setValue,
}: ICiroCreateableTextInput) => {
  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  const [inputValue, setInputValue] = React.useState("");

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        const newValue = [...value, inputValue];
        setValue(newValue);
        setInputValue("");
        event.preventDefault();
    }
  };

  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-flex-col",
        "ciro-v1-items-start",
        "ciro-v1-w-full",
      )}
    >
      {label && (
        <div className={classNames("ciro-v1-pb-2", "ciro-v1-text-neutral-500")}>
          {label}
        </div>
      )}
      <CreatableSelect
        classNames={{
          container: () => (containerWidthClass ? containerWidthClass : ""),
        }}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={(newValue) => {
          setValue(newValue.map((v) => v.value));
        }}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        styles={{
          control: dropdownStylesControl,
          option: dropdownStylesOption,
        }}
        value={value.map((v) => createOption(v))}
      />
    </div>
  );
};

export default CiroCreateableTextInput;
