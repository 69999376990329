import CiroButton, { CiroButtonStyleEnum } from "./CiroButton";
import classNames from "classnames";
import { PlusIcon, MinusIcon } from "@heroicons/react/24/outline";

const CiroNumberInput = ({
  changeValue,
  value,
  analytics,
  increment,
  decrement,
}: {
  changeValue: (num: any) => void;
  value: number;
  analytics: string;
  increment?: () => void;
  decrement?: () => void;
}) => {
  return (
    <div
      className={classNames(
        "ciro-v1-flex",
        "ciro-v1-border",
        "ciro-v1-w-fit",
        "ciro-v1-h-fit",
        "ciro-v1-border-gray-200",
        "ciro-v1-rounded-lg",
      )}
    >
      {increment && (
        <CiroButton
          style={CiroButtonStyleEnum.BORDERLESS}
          analyticsField={analytics + "decremented"}
          onClick={decrement}
          disabled={value <= 1}
        >
          <MinusIcon
            className={classNames("ciro-v1-w-5", "ciro-v1-text-gray-400")}
          />
        </CiroButton>
      )}
      <input
        className={classNames("ciro-v1-w-12", "ciro-v1-text-center")}
        onChange={(e) => {
          let numericValue = e.target.value.replace(/[^0-9]/g, "");
          if (Number(numericValue) > 999) {
            numericValue = "999";
          }
          if (numericValue === "" || numericValue === "0") {
            numericValue = "1";
          }
          changeValue(numericValue);
        }}
        value={value}
      />
      {decrement && (
        <CiroButton
          style={CiroButtonStyleEnum.BORDERLESS}
          analyticsField={analytics + "incremented"}
          onClick={increment}
        >
          <PlusIcon
            className={classNames("ciro-v1-w-5", "ciro-v1-text-gray-400")}
          />
        </CiroButton>
      )}
    </div>
  );
};

export default CiroNumberInput;
