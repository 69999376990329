import { CompaniesFromFiltersInput } from "../../__generated__/graphql";
import { IAccountFilters } from "../../reactHooks/filters/accounts/useAccountFilters";

const createAccountFiltersInput = (filters: IAccountFilters) => {
  const {
    ageFilter,
    allIndustryAssociationsFilter,
    allSpecialtiesFilter,
    allTechnologiesFilter,
    citiesFilter,
    collectionsFilter,
    existsInCrmFilter,
    exportedCompaniesFilter,
    googleNumReviewsFilter,
    googleStarRatingFilter,
    industryAssociationsFilter,
    industryFilter,
    instagramFollowerCountFilter,
    lastActivityDateAfterFilter,
    lastActivityDateBeforeFilter,
    locationsFilter,
    businessTypeFilter,
    multilocationFilter,
    notAgeFilter,
    notCitiesFilter,
    notCollectionsFilter,
    notIndustryAssociationsFilter,
    notIndustryFilter,
    notSpecialtiesFilter,
    notStatesFilter,
    notTechnologiesFilter,
    notZipCodesFilter,
    parentChildTypeFilter,
    numEmployeesFilter,
    openDaysFilter,
    openOpportunityFilter,
    organizationStatusFilter,
    pmsProviderFilter,
    requiredFieldFilter,
    specialtiesFilter,
    statesFilter,
    technologiesFilter,
    textFilter,
    textTypeFilter,
    titleBucketsFilter,
    userStatusFilter,
    yelpNumReviewsFilter,
    yelpStarRatingFilter,
    zipCodesFilter,
  } = filters;
  const filteredCompanyQueryVariables = {
    ageFilter,
    allIndustryAssociationsFilter,
    allSpecialtiesFilter,
    allTechnologiesFilter,
    citiesFilter,
    existsInCrmFilter,
    exportedCompaniesFilter,
    googleNumReviewsFilter,
    googleStarRatingFilter,
    industryAssociationsFilter,
    industryFilter,
    instagramFollowerCountFilter,
    lastActivityDateAfterFilter: lastActivityDateAfterFilter?.valueOf(),
    lastActivityDateBeforeFilter: lastActivityDateBeforeFilter?.valueOf(),
    locationsFilter,
    businessTypeFilter,
    multilocationFilter,
    notAgeFilter,
    notCitiesFilter,
    notIndustryAssociationsFilter,
    notIndustryFilter,
    notSpecialtiesFilter,
    notStatesFilter,
    notTechnologiesFilter,
    notZipCodesFilter,
    numEmployeesFilter,
    parentChildTypeFilter,
    openDaysFilter,
    openOpportunityFilter,
    pmsProviderFilter,
    requiredFieldFilter,
    specialtiesFilter,
    statesFilter,
    technologiesFilter,
    textFilter,
    textTypeFilter: Boolean(textFilter) ? textTypeFilter : undefined,
    titleBucketsFilter,
    userCollectionsFilter: collectionsFilter,
    yelpNumReviewsFilter,
    yelpStarRatingFilter,
    zipCodesFilter,
  } as CompaniesFromFiltersInput;
  if (userStatusFilter.length > 0) {
    filteredCompanyQueryVariables.userStatusFilter = userStatusFilter;
  }
  if (organizationStatusFilter.length > 0) {
    filteredCompanyQueryVariables.organizationStatusFilter =
      organizationStatusFilter;
  }
  if (collectionsFilter.length > 0) {
    filteredCompanyQueryVariables.userCollectionsFilter = collectionsFilter.map(
      (val: any) => parseInt(val),
    );
  }
  if (notCollectionsFilter.length > 0) {
    filteredCompanyQueryVariables.notCollectionsFilter =
      notCollectionsFilter.map((val: any) => parseInt(val));
  }

  return filteredCompanyQueryVariables;
};

export default createAccountFiltersInput;
