import { useCallback, useContext, useState } from "react";
import fileDownload from "js-file-download";
import AppContext from "../../../contexts/AppContext";
import download from "../../../assets/img/icons/download.svg";
import CiroButton, { CiroButtonStyleEnum } from "../CiroButton";

interface IEnrichmentsDownloadButton {
  organizationCiroId?: number | null;
}

function EnrichmentsDownloadButton({
  organizationCiroId,
}: IEnrichmentsDownloadButton) {
  const { accessToken } = useContext(AppContext);
  const [isExporting, setIsExporting] = useState(false);

  const downloadEnrichmentsCSV = useCallback(async () => {
    setIsExporting(true);
    try {
      const exportResponse = await fetch(`/export-enrichments`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ organizationCiroId }),
      });
      const blob = await exportResponse.blob();
      fileDownload(blob, `enrichments.csv`);
    } catch (error) {
      console.error("Error during CSV download:", error);
    } finally {
      setIsExporting(false);
    }
  }, [accessToken, organizationCiroId]);

  return (
    <div>
      <CiroButton
        analyticsField="Download enrichments"
        style={CiroButtonStyleEnum.INVERTED_LOUD}
        disabled={isExporting}
        onClick={() => {
          setIsExporting(true);
          downloadEnrichmentsCSV();
        }}
      >
        <span className="ciro-v1-mr-4">
          <img src={download} alt="Download" width={18} />
        </span>
        Download enrichments
      </CiroButton>
    </div>
  );
}

export default EnrichmentsDownloadButton;
