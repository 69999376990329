import classNames from "classnames";
import CiroTextInput from "../../../../shared/CiroTextInput";
import { IQuestionForPhoneSurveyCard } from "../EnrichmentStepTechniquePhoneSurveyCard";
import {
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import Dropdown from "react-multilevel-dropdown";
import CiroButton, { CiroButtonStyleEnum } from "../../../../shared/CiroButton";
import CiroSwitch from "../../../../shared/CiroSwitch";
import { useState } from "react";
import DownArrowIcon from "../../../../../assets/img/icons/DownArrowIcon";
import CiroTooltipContainer from "../../../../shared/CiroTooltipContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const PhoneSurveyQuestion = ({
  question,
  setQuestion,
  deleteQuestion,
  index,
  inputError,
}: {
  question: IQuestionForPhoneSurveyCard;
  setQuestion: (e: any) => void;
  deleteQuestion: (e: any) => void;
  index: number;
  inputError?: string;
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div
      className={classNames(
        "ciro-v1-bg-gray-100",
        "ciro-v1-border-solid",
        "ciro-v1-border-1",
        "ciro-v1-border-gray-200",
        "ciro-v1-p-1",
        "ciro-v1-mt-3",
        "ciro-v1-rounded-md",
        "ciro-v1-w-full",
      )}
    >
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-justify-between",
          "ciro-v1-items-center",
        )}
      >
        {collapsed ? (
          <div className={classNames("ciro-v1-flex")}>
            <div
              className={classNames("ciro-v1-text-rose-500", "ciro-v1-mr-1")}
            >
              {inputError && (
                <CiroTooltipContainer tooltip={<div>{inputError}</div>}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </CiroTooltipContainer>
              )}
            </div>
            <div className={classNames("ciro-v1-text-slate-400")}>
              {"Q" + (Number(index) + 1)}
            </div>
            <div className={classNames("ciro-v1-ml-2")}>
              {question.questionText}
            </div>
          </div>
        ) : (
          <div className={classNames("ciro-v1-flex")}>
            <div
              className={classNames("ciro-v1-text-rose-500", "ciro-v1-mr-1")}
            >
              {inputError && (
                <CiroTooltipContainer tooltip={<div>{inputError}</div>}>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </CiroTooltipContainer>
              )}
            </div>
            <div>{"Question " + (Number(index) + 1)}</div>
          </div>
        )}
        <div className={classNames("circle-v1-flex")}>
          <CiroButton
            analyticsField="Collapse phone survey question"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => setCollapsed(!collapsed)}
          >
            <div
              className={classNames("ciro-v1-mb-1.5", {
                "ciro-v1-rotate-180": collapsed,
              })}
            >
              <DownArrowIcon stroke="black" height="6" width="10" />
            </div>
          </CiroButton>
          <Dropdown
            title={
              <EllipsisVerticalIcon
                className={classNames(
                  "ciro-v1-h-5",
                  "ciro-v1-text-neutral-500",
                )}
              />
            }
            buttonVariant="tertiary"
            buttonClassName={classNames("!ciro-v1-p-0", "!ciro-v1-shadow-none")}
          >
            <Dropdown.Item
              onClick={() => {
                deleteQuestion(index);
              }}
            >
              <TrashIcon
                className={classNames(
                  "ciro-v1-h-5",
                  "ciro-v1-text-neutral-500",
                )}
              />
              Delete
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      {!collapsed && (
        <>
          <CiroTextInput
            value={question.questionText}
            onChange={(e) => {
              setQuestion({ ...question, questionText: e.target.value });
            }}
          />
          {!question.freeResponse && (
            <div
              className={classNames(
                "ciro-v1-pl-8",
                "ciro-v1-mt-2",
                "ciro-v1-w-full",
              )}
            >
              {question.answers.map((answer, answerIndex) => (
                <div
                  className={classNames(
                    "ciro-v1-mb-2",
                    "ciro-v1-w-full",
                    "ciro-v1-flex",
                  )}
                >
                  <div className={classNames("ciro-v1-grow")}>
                    <CiroTextInput
                      type="text"
                      value={answer}
                      onChange={(e) => {
                        setQuestion({
                          ...question,
                          answers: question.answers
                            .slice(0, answerIndex)
                            .concat(
                              e.target.value,
                              question.answers.slice(answerIndex + 1),
                            ),
                        });
                      }}
                    />
                  </div>
                  <CiroButton
                    analyticsField="Delete answer from phone survey question"
                    style={CiroButtonStyleEnum.UNSTYLED}
                    onClick={() => {
                      setQuestion({
                        ...question,
                        answers: question.answers.filter(
                          (_, i) => i !== answerIndex,
                        ),
                      });
                    }}
                  >
                    <TrashIcon
                      className={classNames(
                        "ciro-v1-h-5",
                        "ciro-v1-text-neutral-500",
                      )}
                    />
                  </CiroButton>
                </div>
              ))}
              <CiroButton
                analyticsField="Add answer to phone survey question"
                style={CiroButtonStyleEnum.UNSTYLED}
                onClick={() => {
                  setQuestion({
                    ...question,
                    answers: [...question.answers, ""],
                  });
                }}
              >
                <div
                  className={classNames("ciro-v1-flex", "ciro-v1-items-center")}
                >
                  <PlusIcon
                    className={classNames(
                      "ciro-v1-mr-2",
                      "ciro-v1-w-5",
                      "ciro-v1-text-gray-400",
                    )}
                  />
                  New Answer
                </div>
              </CiroButton>
            </div>
          )}
          <div
            className={classNames(
              "ciro-v1-pt-2",
              "ciro-v1-flex",
              "ciro-v1-items-center",
            )}
          >
            <CiroSwitch
              checked={question.freeResponse}
              onChange={() =>
                setQuestion({
                  ...question,
                  freeResponse: !question.freeResponse,
                })
              }
            />
            <div className={classNames("ciro-v1-ml-2")}>Open question</div>
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneSurveyQuestion;
