import typesenseClient from "./typesenseClient";

// Limited by Typesense Scoped API Key
const COMPANY_FIELDS_TO_QUERY = [
  "company_name",
  "contact_names",
  "phone_numbers",
  "website",
];

const TYPESENSE_FLAT_COMPANY_COLLECTION = "companies_collection";

const TYPEAHEAD_LIMIT = 10;

const typesenseTypeaheadCompanies = async (text: string) => {
  return typesenseClient
    .collections(TYPESENSE_FLAT_COMPANY_COLLECTION)
    .documents()
    .search({
      q: text,
      query_by: COMPANY_FIELDS_TO_QUERY.join(", "),
      page: 1,
      per_page: TYPEAHEAD_LIMIT,
      highlight_start_tag: "",
      highlight_end_tag: "",
      typo_tokens_threshold: 0, // disables looking for additional results with more typos until result found
      drop_tokens_threshold: 0, // disables looking for additional results by shortening tokens until result found
    });
};

export default typesenseTypeaheadCompanies;
