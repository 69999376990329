import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import XIcon from "../../assets/img/icons/XIcon";
import { UpsertCollectionMethodEnum } from "../../__generated__/graphql";
import CiroCollectionUpsertJobCreator from "../shared/CollectionUploadStatus/CiroCollectionUpsertJobCreator";

interface IUploadCSVUploadStatusProps {
  collectionName: string;
  setCollectionName: (collectionName: string) => void;
  setUploadCSVComplete: (v: boolean) => void;
  storageFilePath: string;
}

const UploadCSVUploadStatus = ({
  collectionName,
  setCollectionName,
  setUploadCSVComplete,
  storageFilePath,
}: IUploadCSVUploadStatusProps) => {
  const uploadChange = {
    name: collectionName,
    csvUploadParams: {
      storageFilePath,
    },
    method: UpsertCollectionMethodEnum.Create,
  };

  return (
    <CiroCard>
      <div
        className={classNames(
          "ciro-v1-flex",
          "ciro-v1-font-medium",
          "ciro-v1-items-center",
          "ciro-v1-justify-between",
          "ciro-v1-pb-4",
        )}
      >
        <span className={classNames("ciro-v1-pr-6")}>Import CSV</span>
        <span
          onClick={() => {
            setCollectionName("");
            setUploadCSVComplete(false);
          }}
          className={classNames("ciro-v1-cursor-pointer")}
        >
          <XIcon />
        </span>
      </div>

      <CiroCollectionUpsertJobCreator
        collectionChange={uploadChange}
        collectionName={collectionName}
        preparingText={`Preparing to match and import records into "${collectionName}"...`}
        completedText={"Upload complete!"}
      />
    </CiroCard>
  );
};

export default UploadCSVUploadStatus;
