import typesense from "typesense";
import {
  TYPESENSE_SEARCH_API_KEY,
  TYPESENSE_URL,
} from "../../clientSideVariables";

const typesenseClient = new typesense.Client({
  nodes: [
    {
      host: TYPESENSE_URL,
      port: 443,
      protocol: "https",
    },
  ],
  apiKey: TYPESENSE_SEARCH_API_KEY,
  connectionTimeoutSeconds: 4,
});

export default typesenseClient;
