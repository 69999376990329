export const API_ORIGIN =
  process.env.NODE_ENV === "production" || process.env.PLASMO_TAG === "prod"
    ? "https://app.ciro.io"
    : "http://localhost:3000";
export const AUTH0_CLIENT_ID = "KAkKHT94yNyr92d4sw0MfCJl4AxznIsY";
export const AUTH0_DOMAIN = "https://auth.ciro.io";
export const POSTHOG_HOST = "https://us.i.posthog.com";
export const POSTHOG_KEY = "phc_tWhBaffEOzwK8dhMfWZ9TJt9L4Ea6k22sfhUaKiBroj";
export const PRODUCTION_ORIGIN = "app.ciro.io";
export const TYPESENSE_URL = "jzb52ikhl6aswygxp.a1.typesense.net";
export const TYPESENSE_SEARCH_API_KEY = "8WMePOEpcPcws0WnfFxo5hYjoZ47AV8w";
