import React from "react";
import { gql, useQuery } from "@apollo/client";
import CiroDropDown from "../shared/CiroDropdown";
import CiroFilterModalDropdownSection from "../shared/CiroFilter/CiroFilterModalDropdownSection";
import { TitleBucketsFilter_TitleBucketsQuery } from "../../__generated__/graphql";

interface ITitleBucketsFilterProps {
  blurred?: boolean;
  resetOffset: () => void;
  setTitleBucketsFilter: (v: string[]) => void;
  titleBucketsFilter: string[];
}

export const TitleBucketsFilter_titleBuckets = gql`
  query TitleBucketsFilter_titleBuckets {
    titleBuckets
  }
`;

function TitleBucketsFilter({
  blurred,
  resetOffset,
  setTitleBucketsFilter,
  titleBucketsFilter,
}: ITitleBucketsFilterProps) {
  const { data: titleBucketsData } =
    useQuery<TitleBucketsFilter_TitleBucketsQuery>(
      TitleBucketsFilter_titleBuckets,
      {
        fetchPolicy: "cache-first",
      },
    );

  const dropdownOptions = (
    (titleBucketsData?.titleBuckets as string[]) || []
  ).map((bucket) => {
    return {
      value: bucket,
      label: bucket,
    };
  });

  const titleBucketsFilterOptions = [
    {
      label: "Include any of:",
      filter: (
        <CiroDropDown
          isMulti
          blurred={blurred}
          options={dropdownOptions}
          value={titleBucketsFilter}
          onChange={(newValue) => {
            setTitleBucketsFilter(newValue);
            resetOffset();
          }}
        />
      ),
    },
  ];

  return (
    <CiroFilterModalDropdownSection
      numFiltersApplied={titleBucketsFilter.length ? 1 : 0}
      filterOptions={titleBucketsFilterOptions}
      sectionTitle="Contact Titles"
    />
  );
}

export default TitleBucketsFilter;
