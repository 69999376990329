import React from "react";
import classNames from "classnames";
import { analytics } from "../../utils/vendors";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import CiroTooltipContainer from "./CiroTooltipContainer";

export enum CiroButtonStyleEnum {
  INVERTED,
  LOUD,
  MAGIC,
  PRIMARY,
  UNSTYLED,
  INVERTED_LOUD,
  BORDERLESS,
  DELETE,
  DANGER,
}

const getCiroButtonClasses = ({
  style,
  customPaddingY = "",
}: {
  style: CiroButtonStyleEnum;
  customPaddingY?: string;
}) => {
  if (style === CiroButtonStyleEnum.UNSTYLED) {
    return "";
  }
  return classNames(
    [
      "ciro-v1-cursor-pointer",
      "disabled:ciro-v1-cursor-not-allowed",
      "ciro-v1-no-underline",
      "ciro-v1-flex",
      "ciro-v1-px-4",
      "ciro-v1-rounded-lg",
      "ciro-v1-text-sm",
      customPaddingY,
    ],
    {
      "ciro-v1-border-gray-300":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED ||
        style === CiroButtonStyleEnum.INVERTED_LOUD,
      "ciro-v1-py-2.5": !customPaddingY,
      "ciro-v1-text-neutral-600":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED_LOUD,
      "ciro-v1-text-purple-500": style === CiroButtonStyleEnum.MAGIC,
      "ciro-v1-text-white": [
        CiroButtonStyleEnum.INVERTED,
        CiroButtonStyleEnum.LOUD,
        CiroButtonStyleEnum.DELETE,
        CiroButtonStyleEnum.DANGER,
      ].includes(style),
      "disabled:ciro-v1-bg-neutral-300":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED ||
        style === CiroButtonStyleEnum.DELETE ||
        style === CiroButtonStyleEnum.DANGER,
      "disabled:ciro-v1-bg-stone-300":
        style === CiroButtonStyleEnum.LOUD ||
        style === CiroButtonStyleEnum.INVERTED_LOUD,
      "disabled:ciro-v1-text-zinc-500":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED ||
        style === CiroButtonStyleEnum.BORDERLESS ||
        style === CiroButtonStyleEnum.DELETE,
      "enabled:ciro-v1-bg-black": style === CiroButtonStyleEnum.INVERTED,
      "enabled:ciro-v1-bg-orange-600": style === CiroButtonStyleEnum.LOUD,
      "enabled:ciro-v1-bg-red-500": 
        style === CiroButtonStyleEnum.DELETE ||
        style === CiroButtonStyleEnum.DANGER,
      "enabled:ciro-v1-bg-white":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED_LOUD ||
        style === CiroButtonStyleEnum.MAGIC,
      "enabled:ciro-v1-border-orange-600": style === CiroButtonStyleEnum.LOUD,
      "enabled:hover:ciro-v1-bg-gray-300":
        style === CiroButtonStyleEnum.PRIMARY,
      "enabled:hover:ciro-v1-bg-orange-700": style === CiroButtonStyleEnum.LOUD,
      "enabled:hover:ciro-v1-bg-white": style === CiroButtonStyleEnum.INVERTED,
      "enabled:hover:ciro-v1-bg-red-700": 
        style === CiroButtonStyleEnum.DELETE ||
        style === CiroButtonStyleEnum.DANGER,
      "enabled:hover:ciro-v1-text-black":
        style === CiroButtonStyleEnum.INVERTED,
      "enabled:hover:ciro-v1-text-purple-800":
        style === CiroButtonStyleEnum.MAGIC,
      "enabled:hover:ciro-v1-bg-orange-600":
        style === CiroButtonStyleEnum.INVERTED_LOUD,
      "ciro-v1-border-1":
        style === CiroButtonStyleEnum.PRIMARY ||
        style === CiroButtonStyleEnum.INVERTED ||
        style === CiroButtonStyleEnum.INVERTED_LOUD ||
        style === CiroButtonStyleEnum.MAGIC,
    },
  );
};

const CiroButton = ({
  analyticsField,
  analyticsProps = {},
  children,
  customClassName,
  customFlexClass = classNames(
    "ciro-v1-items-center",
    "ciro-v1-justify-center",
  ),
  disabled = false,
  disabledTooltip = "",
  href,
  onClick,
  onBlur,
  customPaddingY = "",
  style = CiroButtonStyleEnum.PRIMARY,
  passedRef = null,
}: {
  analyticsField: string;
  analyticsProps?: Object;
  children: any;
  customClassName?: string;
  customFlexClass?: string;
  customPaddingY?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  href?: string;
  onClick?: () => void;
  onBlur?: () => void;
  style?: CiroButtonStyleEnum;
  passedRef?: any;
}) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const handleButtonClick = () => {
    posthog?.capture("buttonClicked", {
      value: analyticsField,
      ...analyticsProps,
    });
    analytics.track("buttonClicked", {
      value: analyticsField,
      ...analyticsProps,
    });
    onClick && onClick();
  };
  const buttonClasses = classNames(
    getCiroButtonClasses({ style, customPaddingY }),
    customClassName,
    customFlexClass,
  );
  const button = (
    <button
      ref={passedRef}
      disabled={disabled}
      className={buttonClasses}
      onClick={handleButtonClick}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
  const innerButton = href ? (
    <div
      onClick={() => {
        handleButtonClick();
        if (/^(http|www|mailto|tel)/.test(href)) {
          // Don't use React Router to navigate to external URLs
          window.open(href, "_blank");
        } else {
          navigate(href);
        }
      }}
    >
      {button}
    </div>
  ) : (
    button
  );
  if (disabled && disabledTooltip) {
    return (
      <CiroTooltipContainer tooltip={disabledTooltip}>
        {innerButton}
      </CiroTooltipContainer>
    );
  } else {
    return innerButton;
  }
};

export default CiroButton;
