import classNames from "classnames";
import CiroCard from "../shared/CiroCard";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import CiroTextInput from "../shared/CiroTextInput";

interface IUploadCSVCollectionNameCardProps {
  collectionName: string;
  modalIsOpen: boolean;
  setCollectionName: (collectionName: string) => void;
  setModalOpen: (modalOpen: boolean) => void;
}

const UploadCSVCollectionNameCard = ({
  collectionName,
  modalIsOpen,
  setCollectionName,
  setModalOpen,
}: IUploadCSVCollectionNameCardProps) => {
  return (
    <CiroCard>
      <div>
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-flex-col",
            "ciro-v1-items-start",
            "ciro-v1-py-4",
          )}
        >
          <div className={classNames("ciro-v1-mb-4")}>
            <CiroTextInput
              placeholder="New collection name"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
            />
          </div>
          <div>
            <CiroButton
              style={CiroButtonStyleEnum.LOUD}
              analyticsField="Continue from CSV Collection Name"
              disabled={!collectionName || modalIsOpen}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Begin CSV Import
            </CiroButton>
          </div>
        </div>
      </div>
    </CiroCard>
  );
};

export default UploadCSVCollectionNameCard;
