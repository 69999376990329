import OneSchemaImporter from "@oneschema/react";
import { UploadCsvToCollectionInitializersResponse } from "../../__generated__/graphql";

interface IOneSchemaModalProps {
  uploadCSVToCollectionInitializers?: UploadCsvToCollectionInitializersResponse;
  isOpen: boolean;
  onSuccess: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

const OneSchemaModal = ({
  uploadCSVToCollectionInitializers,
  isOpen,
  onSuccess,
  setIsOpen,
}: IOneSchemaModalProps) => {
  if (!uploadCSVToCollectionInitializers) {
    return null;
  }
  return (
    <OneSchemaImporter
      /* managing state from your application */
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      /* required config values */
      clientId={uploadCSVToCollectionInitializers.clientId}
      userJwt={uploadCSVToCollectionInitializers.userJwt}
      templateKey={"upload_accounts_csv"}
      /* optional config values */
      importConfig={{
        type: "file-upload",
        url: uploadCSVToCollectionInitializers.signedUrl,
        format: "csv",
      }}
      devMode={process.env.NODE_ENV !== "production"}
      className="oneschema-importer"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 1000,
      }}
      /* handling results */
      onSuccess={onSuccess}
      onCancel={() => setIsOpen(false)}
      onError={(error: any) => console.log(error)}
    />
  );
};

export default OneSchemaModal;
