import classNames from "classnames";
import XIcon from "../../assets/img/icons/XIcon";
import CiroCard from "../shared/CiroCard";
import CiroButton, { CiroButtonStyleEnum } from "../shared/CiroButton";
import ReactPlayer from "react-player";

const EnrichmentFlowIntroCard = ({
  onDismiss,
  shouldHide,
}: {
  onDismiss: () => void;
  shouldHide?: boolean;
}) => {
  
  if (shouldHide) return null;

  return (
    <div className={classNames("ciro-v1-w-full", "ciro-v1-my-2")}>
      <CiroCard>
        <div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-font-medium",
              "ciro-v1-items-center",
              "ciro-v1-justify-between",
              "ciro-v1-mb-6",
            )}
          >
            <span>⚡ Enrich your data</span>
            <span
              onClick={onDismiss}
              className={classNames("ciro-v1-cursor-pointer")}
            >
              <XIcon />
            </span>
          </div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-col",
              "md:ciro-v1-flex-row",
              "md:ciro-v1-items-center",
              "md:ciro-v1-gap-6",
            )}
          >
            <ReactPlayer
              controls={true}
              url="https://youtu.be/lENdvYxQmbo"
              width={"320px"}
              height={"145px"}
            />
            <div
              className={classNames("ciro-v1-text-neutral-500", "ciro-v1-ml-2", "ciro-v1-mt-4", "md:ciro-v1-mt-0")}
            >
              <ol style={{ listStyleType: "disc" }}>
                <li>
                  Select "create new column" to connect your list to new data
                  sources
                </li>
                <li>Draft prompts for Google and CiroBot to search the web</li>
                <li
                  children={
                    "Use {{bracket_notation}} in your prompts to reference variables from previous columns"
                  }
                ></li>
              </ol>
            </div>
          </div>
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-flex-row",
              "ciro-v1-justify-end",
              "ciro-v1-mt-4",
            )}
          >
            <CiroButton
              analyticsField={"closeEnrichmentIntroCard"}
              onClick={onDismiss}
              style={CiroButtonStyleEnum.PRIMARY}
            >
              Ok
            </CiroButton>
          </div>
        </div>
      </CiroCard>
    </div>
  );
};

export default EnrichmentFlowIntroCard;
