import EnrichmentStepCardFiltersList, {
  EnrichmentStepCardFiltersListSchema,
  parseEnrichmentStepsForGraphQL,
  parseEnrichmentStepsForList,
} from "../EnrichmentStepCardFilters/EnrichmentStepCardFiltersList";
import * as yup from "yup";
import {
  EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
  EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
  EnrichmentTechniqueEnum,
} from "../../../../__generated__/graphql";
import { useFragment } from "../../../../__generated__";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EnrichmentStepCardContainer from "../EnrichmentStepCardContainer";
import classNames from "classnames";
import EnrichmentStepSelector from "../EnrichmentStepSelector/EnrichmentStepSelector";
import CiroButton, { CiroButtonStyleEnum } from "../../../shared/CiroButton";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import CiroNumberInput from "../../../shared/CiroNumberInput";
import CiroTextArea from "../../../shared/CiroTextArea";
import PhoneSurveyQuestion from "./PhoneSurveyComponents/PhoneSurveyQuestion";
import { IEnrichmentStepTechniqueCardProps } from ".";
import DownArrowIcon from "../../../../assets/img/icons/DownArrowIcon";
import useEnrichmentStepForm from "../../../../reactHooks/enrichmentFlow/useEnrichmentStepForm";

const PHONE_ONE_KEY = "phone_one";
const PHONE_TWO_KEY = "phone_two";
const PHONE_THREE_KEY = "phone_three";
const CALL_ATTEMPTS_KEY = "call_attempts";
const INTRO_KEY = "intro";
const OUTRO_KEY = "outro";
const QUESTIONS_KEY = "questions";
const CONTEXT_KEY = "context";

export const EnrichmentStepTechniquePhoneSurveyCardSchema = yup
  .object({
    enrichment_technique: yup
      .string()
      .required("Enrichment Technique is required"),
    parentEnrichmentStepInputs: yup.array().of(
      yup.object({
        key: yup.string().required("Key is required"),
        input: yup.string().nullable(),
        required: yup.boolean().required("Required is required"),
        sourceEnrichmentStepId: yup.number().nullable(),
      }),
    ),
    phoneNumbers: yup
      .array()
      .of(
        yup.object({
          stepId: yup.number().nullable(),
          stepInput: yup.string().nullable(),
        }),
      )
      .test(
        "phoneNumbers",
        "At least one phone number is required",
        (value) => value!.length > 0 && value!.some((phone) => phone.stepId),
      ),
    callAttempts: yup.number().required("Call Attempts is required"),
    intro: yup.string().required("Intro is required"),
    outro: yup.string(),
    questions: yup
      .array()
      .test(
        "questions",
        "At least one question is required",
        (value) =>
          value!.length > 0 && value!.some((question) => question.questionText),
      )
      .test(
        "questions",
        "if a question is not free response, it must have answers",
        (value) =>
          value!.every(
            (question) => question.freeResponse || question.answers.length > 0,
          ),
      ),
    context: yup.string(),
    filterEnrichmentSteps: EnrichmentStepCardFiltersListSchema,
  })
  .required();

export interface IQuestionForPhoneSurveyCard {
  questionText: string;
  answers: string[];
  freeResponse: boolean;
}

export interface stepInput {
  stepId: number | null;
  stepInput: string;
}

const EnrichmentStepTechniquePhoneSurveyCard = ({
  loading,
  error,
  enrichmentStep,
  confirmUpdateEnrichmentStep,
  onClose,
}: IEnrichmentStepTechniqueCardProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const enrichmentStepWithFilters = useFragment(
    EnrichmentStepCardFiltersList_EnrichmentStepFragmentDoc,
    enrichmentStep,
  );

  const getDefaultValues = useCallback(() => {
    const companyNameInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "company_name",
    );
    const addressLine1Input = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "address_line_one",
    );
    const addressLine2Input = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "address_line_two",
    );
    const cityInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "city",
    );
    const stateInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "state",
    );
    const zipInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === "zip",
    );
    const phoneNumberOneInput =
      enrichmentStep?.parentEnrichmentStepInputs?.find(
        (stepInput) => stepInput.key === PHONE_ONE_KEY,
      );
    const phoneNumberTwoInput =
      enrichmentStep?.parentEnrichmentStepInputs?.find(
        (stepInput) => stepInput.key === PHONE_TWO_KEY,
      );
    const phoneNumberThreeInput =
      enrichmentStep?.parentEnrichmentStepInputs?.find(
        (stepInput) => stepInput.key === PHONE_THREE_KEY,
      );
    const callAttemptsInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === CALL_ATTEMPTS_KEY,
    );
    const introInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === INTRO_KEY,
    );
    const outroInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === OUTRO_KEY,
    );
    const questionsString = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === QUESTIONS_KEY,
    );
    const contextInput = enrichmentStep?.parentEnrichmentStepInputs?.find(
      (stepInput) => stepInput.key === CONTEXT_KEY,
    );

    const phoneNumbersArray = [
      {
        stepInput: phoneNumberOneInput?.input || "",
        stepId: phoneNumberOneInput?.sourceEnrichmentStep?.id || null,
      },
      {
        stepInput: phoneNumberTwoInput?.input || "",
        stepId: phoneNumberTwoInput?.sourceEnrichmentStep?.id || null,
      },
      {
        stepInput: phoneNumberThreeInput?.input || "",
        stepId: phoneNumberThreeInput?.sourceEnrichmentStep?.id || null,
      },
    ];
    const phoneArray = phoneNumbersArray.filter((phone) => phone.stepId);

    const filterEnrichmentSteps = parseEnrichmentStepsForList(
      enrichmentStepWithFilters,
    );

    const defaultValues = {
      name: enrichmentStep?.name,
      enrichment_technique: EnrichmentTechniqueEnum.PhoneSurvey,
      selected_input: null,
      parentEnrichmentStepInputs: [
        {
          key: "company_name",
          input: JSON.parse(companyNameInput?.input || "null") || "",
          required: true,
          sourceEnrichmentStepId:
            companyNameInput?.sourceEnrichmentStep?.id || null,
        },
        {
          key: "address_line_one",
          input: JSON.parse(addressLine1Input?.input || "null") || "",
          required: false,
          sourceEnrichmentStepId:
            addressLine1Input?.sourceEnrichmentStep?.id || null,
        },
        {
          key: "address_line_two",
          input: JSON.parse(addressLine2Input?.input || "null") || "",
          required: false,
          sourceEnrichmentStepId:
            addressLine2Input?.sourceEnrichmentStep?.id || null,
        },
        {
          key: "city",
          input: JSON.parse(cityInput?.input || "null") || "",
          required: false,
          sourceEnrichmentStepId: cityInput?.sourceEnrichmentStep?.id || null,
        },
        {
          key: "state",
          input: JSON.parse(stateInput?.input || "null") || "",
          required: false,
          sourceEnrichmentStepId: stateInput?.sourceEnrichmentStep?.id || null,
        },
        {
          key: "zip",
          input: JSON.parse(zipInput?.input || "null") || "",
          required: false,
          sourceEnrichmentStepId: zipInput?.sourceEnrichmentStep?.id || null,
        },
      ],
      phoneNumbers:
        phoneArray && phoneArray.length > 0
          ? (phoneArray.map((phone) => {
              return {
                stepId: phone.stepId,
                stepInput: JSON.parse(phone.stepInput || "null") || "",
              };
            }) as stepInput[])
          : ([{ stepId: null, stepInput: "" }] as stepInput[]),
      callAttempts: callAttemptsInput
        ? JSON.parse(callAttemptsInput.input || "1")
        : 1,
      intro: JSON.parse(introInput?.input || "null") || "",
      outro: JSON.parse(outroInput?.input || "null") || "",
      questions: questionsString
        ? (JSON.parse(
            questionsString?.input || "[]",
          ) as IQuestionForPhoneSurveyCard[])
        : ([
            { questionText: "", answers: [], freeResponse: false },
          ] as IQuestionForPhoneSurveyCard[]),
      context: JSON.parse(contextInput?.input || "null") || "",
      filterEnrichmentSteps: filterEnrichmentSteps,
    };

    return defaultValues;
  }, [enrichmentStep, enrichmentStepWithFilters]);

  const formatAndSubmitRequest = (data: any) => {
    const phoneNumberOneInput = {
      key: "phone_one",
      required: true,
      input: JSON.stringify(data.phoneNumbers[0].stepInput),
      sourceEnrichmentStepId: data.phoneNumbers[0].stepId,
    };
    const phoneNumberTwoInput = {
      key: "phone_two",
      required: false,
      input: JSON.stringify(data.phoneNumbers[1]?.stepInput) || "",
      sourceEnrichmentStepId: data.phoneNumbers[1]?.stepId || null,
    };
    const phoneNumberThreeInput = {
      key: "phone_three",
      required: false,
      input: JSON.stringify(data.phoneNumbers[2]?.stepInput || ""),
      sourceEnrichmentStepId: data.phoneNumbers[2]?.stepId || null,
    };
    const callAttemptsInput = {
      key: CALL_ATTEMPTS_KEY,
      required: true,
      input: data.callAttempts.toString(),
      sourceEnrichmentStepId: undefined,
    };

    const introInput = {
      key: INTRO_KEY,
      required: false,
      input: JSON.stringify(data.intro),
      sourceEnrichmentStepId: undefined,
    };

    const outroInput = {
      key: OUTRO_KEY,
      required: false,
      input: JSON.stringify(data.outro),
      sourceEnrichmentStepId: undefined,
    };

    const questionsInput = {
      key: QUESTIONS_KEY,
      required: true,
      input: JSON.stringify(data.questions),
      sourceEnrichmentStepId: undefined,
    };

    const contextInput = {
      key: CONTEXT_KEY,
      required: false,
      input: JSON.stringify(data.context),
      sourceEnrichmentStepId: undefined,
    };

    const formattedParentEnrichmentStepInputs =
      data.parentEnrichmentStepInputs.map((parentEnrichmentStepInput: any) => {
        return {
          ...parentEnrichmentStepInput,
          input: JSON.stringify(parentEnrichmentStepInput.input),
        };
      });

    confirmUpdateEnrichmentStep({
      data: {
        id: enrichmentStep?.id || null,
        enrichmentStepInput: {
          enrichment_technique: data.enrichment_technique,
          selected_input: null,
          parentEnrichmentStepInputs: [
            ...formattedParentEnrichmentStepInputs,
            phoneNumberOneInput,
            phoneNumberTwoInput,
            phoneNumberThreeInput,
            callAttemptsInput,
            introInput,
            outroInput,
            questionsInput,
            contextInput,
          ],
          filterEnrichmentSteps: parseEnrichmentStepsForGraphQL(
            data.filterEnrichmentSteps,
          ),
        },
      },
    });
  };

  const methods = useEnrichmentStepForm({
    resolver: yupResolver(EnrichmentStepTechniquePhoneSurveyCardSchema),
    defaultValues: getDefaultValues(),
  });
  const {
    control,
    formState: { errors, isDirty },
    register,
    setValue,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(getDefaultValues());
  }, [getDefaultValues, reset]);

  const [
    parentEnrichmentStepInputs,
    filterEnrichmentSteps,
    phoneNumbers,
    callAttempts,
    questions,
  ] = useWatch({
    control,
    name: [
      "parentEnrichmentStepInputs",
      "filterEnrichmentSteps",
      "phoneNumbers",
      "callAttempts",
      "questions",
    ],
  });

  const phoneInputs = () => {
    //todo, break this out into its own reusable component for lists of erichment step selectors
    const phoneInputs = [];
    for (let i = 0; i < phoneNumbers.length; i++) {
      phoneInputs.push(
        <div key={i} className={classNames("ciro-v1-pt-1", "ciro-v1-flex")}>
          <EnrichmentStepSelector
            inputError={i === 0 ? errors.phoneNumbers?.message : undefined}
            stepVariable={{
              stepId: phoneNumbers[i].stepId || null,
              stepInput: phoneNumbers[i].stepInput,
            }}
            setStepVariable={(newVariable) => {
              setValue(`phoneNumbers.${i}.stepId`, newVariable.stepId || null);
              setValue(
                `phoneNumbers.${i}.stepInput`,
                newVariable?.stepInput || "",
              );
            }}
          />
          {phoneNumbers.length > 1 && (
            <CiroButton
              analyticsField="Delete phone number from phone survey"
              style={CiroButtonStyleEnum.UNSTYLED}
              onClick={() => {
                setValue(
                  "phoneNumbers",
                  phoneNumbers.filter((_, index) => index !== i),
                );
              }}
            >
              <div className={classNames("ciro-v1-flex")}>
                <TrashIcon
                  className={classNames("ciro-v1-ml-10", "ciro-v1-w-4")}
                />
              </div>
            </CiroButton>
          )}
        </div>,
      );
    }
    return phoneInputs;
  };

  return (
    <EnrichmentStepCardContainer
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSave={handleSubmit(formatAndSubmitRequest)}
      enrichmentStep={useFragment(
        EnrichmentStepCardContainer_EnrichmentStepFragmentDoc,
        enrichmentStep,
      )}
      onClose={onClose}
    >
      <div
        className={classNames(
          "ciro-v1-p-6",
          "ciro-v1-bg-stone-200",
          "ciro-v1-rounded-lg",
        )}
      >
        <ul style={{ listStyle: "disc" }}>
          <li>
            Once you click “Run” on a phone survey, it will run for the exact
            script and questions you entered. You can change the script and
            questions, however these will only run for new rows that have not
            yet been called. We typically do not recommend calling the same
            number with a new script.
          </li>
          <li>
            If you want to completely cancel an ongoing phone survey, delete the
            enrichment column.
          </li>
        </ul>
      </div>
      <div className={classNames("ciro-v1-pt-4")}>Company Name</div>
      <EnrichmentStepSelector
        inputError={errors.parentEnrichmentStepInputs?.[0]?.message}
        stepVariable={{
          stepId: parentEnrichmentStepInputs[0].sourceEnrichmentStepId || null,
          stepInput: parentEnrichmentStepInputs?.[0].input,
        }}
        setStepVariable={(newVariable) => {
          setValue(
            "parentEnrichmentStepInputs.0.sourceEnrichmentStepId",
            newVariable.stepId || null,
          );
          setValue(
            "parentEnrichmentStepInputs.0.input",
            newVariable?.stepInput || "",
          );
        }}
      />
      <div className={classNames("ciro-v1-flex", "ciro-v1-mt-2")}>
        <div>Address</div>
        <CiroButton
          analyticsField="Collapse Phone Survey Address Section"
          style={CiroButtonStyleEnum.UNSTYLED}
          onClick={() => setCollapsed(!collapsed)}
        >
          <div
            className={classNames("ciro-v1-ml-2", {
              "ciro-v1-rotate-180": collapsed,
            })}
          >
            <DownArrowIcon stroke="black" height="6" width="10" />
          </div>
        </CiroButton>
      </div>
      {!collapsed && (
        <>
          <div className={classNames("ciro-v1-pt-4")}>Address Line 1</div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInputs?.[1]?.message}
            stepVariable={{
              stepId:
                parentEnrichmentStepInputs[1].sourceEnrichmentStepId || null,
              stepInput: parentEnrichmentStepInputs?.[1].input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInputs.1.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInputs.1.input",
                newVariable?.stepInput || "",
              );
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>Address Line 2</div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInputs?.[2]?.message}
            stepVariable={{
              stepId:
                parentEnrichmentStepInputs[2].sourceEnrichmentStepId || null,
              stepInput: parentEnrichmentStepInputs?.[2].input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInputs.2.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInputs.2.input",
                newVariable?.stepInput || "",
              );
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>City</div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInputs?.[3]?.message}
            stepVariable={{
              stepId:
                parentEnrichmentStepInputs[3].sourceEnrichmentStepId || null,
              stepInput: parentEnrichmentStepInputs?.[3].input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInputs.3.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInputs.3.input",
                newVariable?.stepInput || "",
              );
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>State</div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInputs?.[4]?.message}
            stepVariable={{
              stepId:
                parentEnrichmentStepInputs[4].sourceEnrichmentStepId || null,
              stepInput: parentEnrichmentStepInputs?.[4].input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInputs.4.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInputs.4.input",
                newVariable?.stepInput || "",
              );
            }}
          />
          <div className={classNames("ciro-v1-pt-4")}>Zip</div>
          <EnrichmentStepSelector
            inputError={errors.parentEnrichmentStepInputs?.[5]?.message}
            stepVariable={{
              stepId:
                parentEnrichmentStepInputs[5].sourceEnrichmentStepId || null,
              stepInput: parentEnrichmentStepInputs?.[5].input,
            }}
            setStepVariable={(newVariable) => {
              setValue(
                "parentEnrichmentStepInputs.5.sourceEnrichmentStepId",
                newVariable.stepId || null,
              );
              setValue(
                "parentEnrichmentStepInputs.5.input",
                newVariable?.stepInput || "",
              );
            }}
          />
        </>
      )}
      <div className={classNames("ciro-v1-flex", "ciro-v1-items-center")}>
        <div className={classNames("ciro-v1-pt-4")}>Phone Numbers</div>
        {phoneNumbers.length < 3 && (
          <CiroButton
            analyticsField="Add phone number to phone survey"
            style={CiroButtonStyleEnum.UNSTYLED}
            onClick={() => {
              setValue("phoneNumbers", [
                ...phoneNumbers,
                { stepId: null, stepInput: "" },
              ]);
            }}
          >
            <PlusIcon
              className={classNames(
                "ciro-v1-w-4",
                "ciro-v1-ml-1",
                "ciro-v1-mt-4",
                "ciro-v1-text-gray-400",
                "ciro-v1-bg-gray-100",
                "ciro-v1-rounded",
              )}
            />
          </CiroButton>
        )}
      </div>
      {phoneInputs()}
      <div className={classNames("ciro-v1-pt-4")}>Number of Call Attempts</div>
      <CiroNumberInput
        changeValue={(num) => setValue("callAttempts", num)}
        value={callAttempts}
        analytics="Number of Call Attempts"
        decrement={() => setValue("callAttempts", Number(callAttempts) - 1)}
        increment={() => setValue("callAttempts", Number(callAttempts) + 1)}
      />
      <div className={classNames("ciro-v1-py-4")}>Call Script</div>
      <CiroTextArea
        error={errors.intro?.message?.toString()}
        placeholder="Opening..."
        register={register("intro")}
      />
      {questions.map((question, index) => (
        <PhoneSurveyQuestion
          inputError={errors.questions?.message?.toString()}
          question={question}
          setQuestion={(question: IQuestionForPhoneSurveyCard) =>
            setValue(`questions.${index}`, question)
          }
          deleteQuestion={
            questions.length > 1
              ? () => {
                  setValue(
                    "questions",
                    questions.filter((_, i) => i !== index),
                  );
                }
              : () => {}
          }
          index={index}
        />
      ))}
      <CiroButton
        analyticsField="Add question to phone survey"
        style={CiroButtonStyleEnum.UNSTYLED}
        onClick={() => {
          setValue("questions", [
            ...questions,
            { questionText: "", answers: [], freeResponse: false },
          ]);
        }}
      >
        {questions.length < 10 && (
          <div
            className={classNames(
              "ciro-v1-flex",
              "ciro-v1-items-center",
              "ciro-v1-py-3",
            )}
          >
            <PlusIcon
              className={classNames(
                "ciro-v1-mr-2",
                "ciro-v1-p-1",
                "ciro-v1-w-6",
                "ciro-v1-stroke-2",
                "ciro-v1-text-orange-600",
                "ciro-v1-bg-amber-500/40",
                "ciro-v1-rounded",
              )}
            />
            <div className={classNames("ciro-v1-text-orange-600")}>
              New Question
            </div>
          </div>
        )}
      </CiroButton>
      <CiroTextArea
        error={errors.outro?.message?.toString()}
        placeholder="Closing..."
        register={register("outro")}
      />
      <div className={classNames("ciro-v1-py-4")}>Additional Notes</div>
      <CiroTextArea
        error={errors.context?.message?.toString()}
        placeholder="Add notes for the caller..."
        register={register("context")}
      />
      <div className={classNames("ciro-v1-my-4")}>
        <FormProvider {...methods}>
          <EnrichmentStepCardFiltersList
            filterEnrichmentSteps={filterEnrichmentSteps}
          />
        </FormProvider>
      </div>
    </EnrichmentStepCardContainer>
  );
};

export default EnrichmentStepTechniquePhoneSurveyCard;
